.headerBook-main {
    display: flex;
    flex-direction: row;
    flex: 1;
    min-height: 9vh;
}

.headerBook-title {
    flex: 1 1;
    color: #7e187a;
    /* max-width: -webkit-fit-content; */
    max-width: max-content;
    height: fit-content;
    margin-top: auto;
    margin-left: 3.5vw;
    margin-bottom: auto;
}
.headerBook-selector {
    border-radius: 8px;
    flex: 1;
    margin: auto auto auto 1vw;
    max-width: 17vw;
    height: fit-content;
    padding: 8px;
    cursor: pointer;
    color: #1f285a;
    border: 1px solid #dfe2e6;
}
.headerBook-btns-album {
    flex: 1;
    border-radius: 8px;
    border: solid 1px #1f285a;
    background: #fff;
    padding: 0 0;
    height: 3.8vh;
    max-width: 9.6vh;
    margin: auto 0.5vw auto auto;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}
.headerBook-btns-album-eye {
    padding: 0 1.2vh;
    display: flex;
    height: 100%;
}
.headerBook-btns-album-trash {
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #1f285a;
    margin: auto ;
}
.headerBook-btns-album > div > div > img {
    width: 20px;
    height: 20px;
    margin: auto;
}
.headerBook-btns-album > div {
    flex: 1;
}
.headerBook-add-two-pages {
    cursor: pointer;
    flex: 1;
    background: #1f285a;
    color: #fff;
    font-size: 14px;
    padding: 0.9vh 1.5vw;
    border-radius: 8px;
    height: fit-content;
    max-width: fit-content;
    margin: auto 3.5vw auto 1vw;
}
