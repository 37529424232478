.image-editor-modal {
  display: flex;
  flex-direction: column;
}

.menu-edit-img {
  margin-top: 5px;
  height: 0px;
  width: auto;
  visibility: hidden;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  padding: 5px 10px;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
}

 .menu-edit-img > img, .image-editor-edit {
  visibility: hidden;
  margin: 0px 5px;
  width: 25px;
  height: 0px;
  padding: 2px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 100%;
  -webkit-transition: height 0.75s;
  transition: height 0.75s;
}
.image-editor-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image-editor-content-canvas-img {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.image-editor-accept-button {
  width: 150px;
  height: 47px;
  border-radius: 8px;
  background-color: #7e187a;
  margin-right: 57px;

  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  color: #ffffff;
}
.image-editor-cancel-button {
  width: 150px;
  height: 47px;
  border-radius: 8px;
  background: white;

  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  color: #9b9b9b;
}
.image-editor-header {
  font-size: 35px;
  font-weight: bold;
  color: #7e187a;
  font-family: Roboto;
  text-align: center;
  margin: 2vh 0;
}
.image-editor-picture-slider {
  display: flex;
  /* margin-top: 28px; */
}
.imagen-editor-slider {
  width: 403px;
  margin-left: 19px;
  margin-right: 19px;
}
.imagen-editor-actions {
  /* margin-top: 42px; */
  text-align: center;
}
