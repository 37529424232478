.main-user-vitalangels {
    background: #ffffff;
    flex: 1;
    margin-top: 1vh;
    display: flex;
    flex-direction: column
}
.user-vitalangels-header {
    margin: 1.7vw 1.7vw 0;
    display: flex;
    flex-direction: row;
    flex: 1;
    max-height: 24px;
}
.user-vitalangels-header-title {
    color: #92117e;
    font-size: 16px;
    font-weight: 600;
    flex: 1;
    text-align: left;
}
.user-vitalangels-friends-empty {
    font-size: 16px;
    color: #b3b3b3;
    flex: 6;
    display: inline-table;
    margin: auto;
}
.user-vitalangels-friends {
    flex: 6;
    display: flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
}
.box-user-firend {
    flex: 1 1;
    height: 24vh;
    min-width: 22vh;
    max-width: 20vh;
    margin: 1vh 0px 1vh 0.8vw;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(153, 153, 153, 0.5);
    background-color: #ffffff;
    text-align: center;
}
.user-vitalangels-help {
    flex: 1;
    font-size: 12px;
    margin: 3vh 1.7vw;
    text-align: center;
    color: #1f285a;
}
