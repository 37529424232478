.main-dialog-suscription > div > div {
    height: 85vh;
    width: 70vw;
    max-width: none;
    font-family: Roboto;
    text-align: center;
    display: flex;
    flex-direction: column;
    min-height: 566px;
    min-width: 852px;
}
.suscription-dowload-pdf {
    position: absolute;
    margin: 0 0 0 auto;
    width: auto;
    display: flex;
}
.suscription-title {
    margin: 5vh auto 2vh auto;
    width: auto;
    height: auto;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: -0.1px;
    text-align: center;
    color: #7e187a;
}
.suscription-content {
    display: flex;
    flex-direction: row;
    height: 67vh;
    overflow-y: auto;
    margin: 0 2vw;
    min-height: 451px;
}
.suscription-content > div {
    flex: 1;
    box-shadow: 0 2px 4px 0 rgba(153, 153, 153, 0.5);
    margin: 3vh 0.5vw;
    border-radius: 5px;
    min-width: 153px
    /* height: fit-content; */
}
.suscription-element {
    display: flex;
    flex-direction: column;
    color: #1f285a;
    cursor: context-menu;
}
.suscription-element-img-favourite {
    display: flex;
    height: 20px;
}
.suscription-element-img-favourite > img {
    height: 20px;
    width: 20px;
    margin: 1vh 1vh 0 auto;
}
.suscription-element-favorite {
    background-image: linear-gradient(152deg, #891558, #7e187a 52%, #1f285a);
    color: white;
}
.suscription-element-favorite > div {
    color: white;
}
.suscription-element-favorite > button {
    color: white;
    background: transparent;
    border-top: 2px solid #ffffff;
}
.suscription-content-type {
    font-size: 11px;
    font-weight: 300;
    letter-spacing: 0.7px;
    color: #1f285a;
    padding: 0px 2vw;
    margin: 0 0 1vh 0;
}
.suscription-content-advice {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: #92117e;
    padding: 0px 2vw;
    margin: 0 0 3.5vh 0
}
.suscription-content-info {
    font-size: 12px;
    line-height: 1.5;
    color: #1f285a;
    padding: 0px 2vw;
}
.suscription-content-info > div {
    margin: 0.8vh 0;
}
/* .suscription-content-aditional-info:hover {
    color: white;
    background: #1f285a;
    border: 1px solid #ffffff;
    border-radius: 8px;
} */
.suscription-content-aditional-info {
    border: 1px solid transparent;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    text-align: center;
    width: fit-content;
    margin: auto;
    cursor: pointer;
}
.suscription-content-aditional-info > img {
    width: 15px;
    height: 15px;
    margin: auto 0.3vw;
}
.suscription-content-cost {
    font-size: 55px;
    font-weight: 300;
    line-height: 0.64;
    letter-spacing: -5px;
    color: #1f285a;
    padding: 0px 2vw;
    margin: 2vh 0 2vh 0;
}
.suscription-content-cost > label {
    font-size: 24.5px;
}
.suscription-content-has-a-iva {
  font-family: Roboto;
  font-size: 11px;
  color: #1f285a;
  padding: 0px 2vw;
}

.suscription-content-subscribe {
    cursor: pointer;
    height: 58px;
    font-size: 14px;
    font-weight: bold;
    font-family: roboto;
    border: 0px solid transparent;
    background-color: #1f285a;
    color: white;
    border-top: 2px solid #1f285a;
    width: 100%;    
    margin: auto 0px 0px 0px;
    border-radius: 0 0 8px 8px;
}
.suscription-content-subscribe-active {
    cursor: auto;
    background-color: white;
    color: #1f285a;
    border-top: 2px solid #1f285a;
}
.suscription-bottom-text {
    font-weight: bold;
    height: 5vh;
    min-height: 30px;
    width: auto;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
    color: #1f285a;
}

.main-dialog-type-suscription > div > div {
    height: 52vh;
    min-height: 487px;
    width: inherit;
    max-width: none;
    font-family: Roboto;
    text-align: center;
    display: flex;
    flex-direction: column;
    z-index: 10;
    padding: 1vh 1vw;
}

.dialog-type-suscription-title {
    color: #7e187a;
    font-weight: bold;
    font-family: Roboto;
    margin-top: 4vh;
}

.dialog-type-suscription-plan-selected {
    font-size: 20px;
    color: #1f285a;
    font-family: Roboto;
    margin: 2.3vh 1vw 1.5vh;
}

.dialog-type-suscription-type-sub {
    display: flex;
    flex-direction: row;
    margin: 1vh 4vw 0 4vw;
}
.dialog-type-suscription-type-sub > div > button {
    background: #1f285a;
    color: #ffffff;
    border: 0px;
    border-radius: 8px;
    font-size: 14px;
    padding: 1vh 1vw;
    margin: 2vh 1vw;
    cursor: pointer;
}
.dialog-type-suscription-type-sub > div {
    flex: 1;
    font-family: Roboto;
    font-size: 16px;
    color: #1f285a;
}

.dialog-type-suscription-type-sub > div > label { 
    font-weight: bold;
    font-size: 16px;
    margin-top: 1vh;
}
.dialog-type-suscription-type-sub > div > div {
    margin-bottom: 1vh;
}
.dialog-type-suscription-type-sub-price {
    font-size: 18px;
}
.dialog-type-suscription-text {
    font-weight: bold;
}
.dialog-type-suscription-text-price {}
.dialog-type-suscription-discound-icon {
    height: 60px;
    width: 60px;
    position: absolute;
    margin: -60px 0 0 160px;
}
.dialog-type-suscription-exception {
    font-family: Roboto;
    font-size: 12px;
    color: #1f285a;
}
.dialog-type-suscription-method {
    display: flex;
    flex-direction: column;
}
.dialog-type-suscription-method > * {
    flex: 1;
    font-size: 12px;
    margin: 1vh 1vw;
}
.dialog-type-suscription-method > img {
    /* width: fit-content; */
    object-fit: contain;
    max-height: 3vh;
}
.dialog-type-suscription-policy-rules {
    width: fit-content;
    font-size: 12px;
    margin: 2vh auto;
    cursor: pointer;
}
.dialog-type-suscription-policy-rules-link {
    cursor: pointer;
    text-decoration: underline;
}
