.main-uploadDocOnSection > div > div {
    height: 35vh;
    width: 30vw;
    max-width: none;
    font-family: Roboto;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: #1f285a;
    padding: 0 2.5vw;
}

.uploadDocOnSection-title {
    margin: 2vh 0px;
    font-size: 30px;
    font-weight: bold;
    color: #7e187a;
}
.uploadDocOnSection-import {
    display: flex;
    flex-direction: row;
    margin: 1vh 0;
}
.uploadDocOnSection-import-info {
    flex: 1;
    font-size: 14px;
}
.uploadDocOnSection-import-select {
    flex: 1.5;
    font-size: 14px;
}
.uploadDocOnSection-input {
    font-size: 14px;
    color: #1f285a;
    cursor: pointer;
    width: auto;
    height: 3vh;
    margin: 3vh 0;
    font-family: Roboto;
    font-weight: bold;
}
