.main-DocumentPreviewMiniature {
    height: 17vh;
    width: 8.5vw;
    border-radius: 3.2px;
    box-shadow: 0 2px 3px 0 rgba(153, 153, 153, 0.5);
    background-color: #ffffff;
    display: flex;
    margin: 1vh 0.5vw;
    flex-direction: column;
}

.DocumentPreviewMiniature-img {
    flex: 1;
    text-align: right;
    margin: 1vh 1vw 0 0;
}
.DocumentPreviewMiniature-img > img {
    height: 15px;
    width: 15px;
    cursor: pointer;
}
.DocumentPreviewMiniature-miniature {
    flex: 7;
}
.DocumentPreviewMiniature-miniature > img {
    margin: 1.75vh auto;
    display: flex;
    width: auto;
    height: 50%;
}
.DocumentPreviewMiniature-file-name {
    flex: 2;
    color: #92117e;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    width: 10ch; /*test*/
    margin: auto;
}
.DocumentPreviewMiniature-dete-imported {
    flex: 2;
    color: #1f285a;
    text-align: center;
    font-size: 12px;
    max-height: fit-content;
    font-size: 12px;
    margin: 0vh auto 1vh auto;
}
