    .main-home {
        height: 100vh;
        display: flex;
        position: fixed;
    }
    .home-sidebar-prof {
        height: 0vh;
    }
    .home-header {
        opacity: 0.9;
        background-color: #ffffff;
        height: 9vh;
        display: flex;
        flex-direction: row;
        font-size: 20px;
        color: #1f285a;
    }
    .home-header-logo {
        flex: 4;
        text-align: left;
        margin: auto 26px 14px 80px;
    }

    .home-header-logo > img {
        cursor: pointer;
    }
    
    .home-header-acces-menu {
        display: flex;
        flex-direction: row;
        flex: 1;
        text-align: right;
        font-size: 20px;
        text-align: right;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        margin: auto 70px 14px 80px;
    }
    .home-header-acces-menu > div {
        flex: 1;
        margin: 0 12%;
        text-align: center;
        cursor: pointer;
    }
    .home-content {
        height: 100vh;
        color: #fff;
        text-align: left;
    }
    .home-content-info-content {
        display: flex;
        flex-direction: row;
        height: 100vh;
    }
    .home-content-info-content > div {
        margin: auto 0px;
    }
    .home-content-register, .home-content-login {
        width: 25vw;
        height: auto;
        background-color: #ffffff;
    }
    .home-content-login  > h2 {
        color: #1f285a;
    }
    .home-content-lang {
        text-align: right;
        margin: 0 0px 0 auto;
    }
    .home-content-combo-lang {
        width: 106px;
        height: 35px;
        color: white;
        border-radius: 5px;
        border: none;
        background-color: rgba(0, 0, 0, 0);
    }
    .home-content-combo-lang :hover {
        background-color: rgba(0, 0, 0, 0.9);
    }
    .home-content-combo-lang {
        background: url(http://i62.tinypic.com/2e3ybe1.jpg) no-repeat right center;
    }
    .home-content-combo-lang option{
        background-color: rgb(70, 71, 69);
        font-size: 16px;
        color: white;
    }
    /* ##### WELCOME PAGE #####*/
        .home-content-info-element-text {
            width: 30vw;
            padding: 0px 5vw 0 15vw;
            -moz-transition: all 0.75s;
            -webkit-transition: all 0.75s;
            -o-transition: all 0.75s;
            transition: all 0.75s;
        }
        .home-content-info-element-img {
            text-align: center;
            width: 50vw;
        }
        .home-content-info-content-welcome {
            font-size: 14px;
            letter-spacing: 0.9px;
        }
        .home-content-info-content-title {
            font-size: 35px;
            line-height: 1.14;
            letter-spacing: 0px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            width: auto;
            margin: 2vh 0px;
        }
        .home-content-info-content-info {
            /* font-size: 32px; */
            height: auto;
            margin-bottom: 20px;
        }
        .home-content-button:hover {
            background-color: #ffffff;
            color: #7e187a ;
        }
        .home-content-button, .home-content-register-button {
            width: 150px;
            height: 47px;
            border-radius: 8px;
            background-color: transparent;
            border-radius: 8px;
            border: solid 1px #ffffff;
            /* font-weight: bold; */
            color: white;
            font-size: 16px;
            cursor: pointer;
            margin: 2vh 0 0;
        }
    /* ##### END WELCOME PAGE #####*/

    /* ##### LOGIN PAGE ##### */
    .home-content-login-close {
        text-align: right;
        cursor: pointer;
        margin: 0px 0px 0px auto;
        width: fit-content;
        margin-bottom: 25px;
    }
    .home-content-login-close > img {
        height: 25px;
        width: 25px;
    }
    .home-content-login-start {
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.9px;
        color: #1f285a;
    }
    .home-content-recover-text {
        margin: 20px 0px 15px 0px;
        font-size: 14px;
        line-height: 1.14;
        color: #b3b3b3;
    }
    .home-content-login-input > div {
        text-align: left;
        color: #1f285a;
        font-weight: bold;
    }
    .home-content-login-input > input {
        font-size: 14px;
        text-align: left;
        padding-top: 1px;
        padding-bottom: 1px;
        border-color: #1f285a;
    }
    .home-content-login-input > input:focus {
        border: 2px solid #7e187a !important;
        opacity: 1;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .home-content-login-input-empty > input {
        border: 1px solid #dfe2e6 !important;
        opacity: 0.6;
        padding-top: 1px;
        padding-bottom: 1px;
    }
    .home-content-login-botonera {
        display: flex;
        flex-direction: row;
        margin: 7% 0px;
    }
    .home-content-login-botonera > button {
        flex: 1;
        border-radius: 8px;
        width: 161px;
        height: 40px;
        border: 1px solid #1f285a;
        cursor: pointer;
        /* margin: 0px 5%; */
    }
    .home-content-login-botonera-btn-registre {
        background-color: #ffffff;
        color: #1f285a;
        margin-right: 1vw;
    }
    .home-content-login-botonera-btn-registre:hover {
        background-color: #1f285a;
        color: white;
    }
    .home-content-login-botonera-btn-loging {
        background-color: #1f285a;
        color: white;
        margin-left: 1vw;
    }
    .home-content-login-botonera-btn-loging:hover {
        background-color: #7e187a;
        border-color: #7e187a;
    }
    .home-content-login-alternative {
        border-top: solid 1px #e5e7ea;
        text-align: center;
        margin: 5% 0 0 0;
        padding: 5% 0 0 0;
    }
    .home-content-login-alternative > label {
        font-size: 14px;
        color: #1f285a;
        font-weight: bold;
    }
    .home-content-login-google-btn, .home-content-login-facebook-btn, .home-content-login-facebook-btn-text {
        margin: 20px auto 20px auto;
        border: 0;
        font-size: 14px;
        cursor: pointer;
        color: white;
        width: 220px;
        height: 40px;
        display: flex;
        border-radius: 5px;
        padding: 0px;
    }
    .home-content-login-facebook-btn {
        background-color: #3b5998;
        margin: 0px auto 0px auto;
        margin-top: 15px;
    }
    .home-content-login-facebook-btn-text {
        margin: auto;
        height: fit-content;
        width: fit-content;
    }
    .home-content-login-facebook-btn-img {
        background-color: #263c68;
        width: 15px;
        height: 15px;
        padding: 12.5px 12.5px 12.5px 12.5px;
        border-radius: 5px 0px 0px 5px;
    }
    .home-content-login-google-btn > div {
        margin: auto;
    }

    .home-content-login-google-btn {
        background-color: #f44336;
    }
    .home-content-login-google-btn > img {
        background-color: #c53126;
        width: 15px;
        height: 15px;
        padding: 12.5px 12.5px 12.5px 12.5px;
        border-radius: 5px 0px 0px 5px;
    }
    .home-content-login-google-btn > div {
        margin: auto;
    }
    
    /* ##### END LOGIN PAGE ##### */

    /* ##### REGISTER PAGE ##### */
        .home-content-register, .home-content-login {
            color: #1f285a;
            padding: 5vh 2vw 5vh 2vw;
            text-align: left;
        }
        .home-content-register > h1 {
            margin-top: 0;
        }
        .home-content-register-title {} 
        .home-content-register-input {
            width: 370px;
            height: 48px;
            border-radius: 8px;
            background-color: rgba(255, 255, 255, 0.82);
        }
        .home-content-policy-rules {
            width: fit-content;
            font-size: 12px;
            margin: 3% 0;
            cursor: pointer;
        }
        .home-content-policy-rules-link {
            cursor: pointer;
            text-decoration: underline;
        }
        .home-content-register-button {
            background-color: #1f285a;
            color: white;
            font-size: 14px;
            border-radius: 8px;
            width: 100%;
            height: 40px;
            border: 2px solid #1f285a;
            cursor: pointer;
        }
        .home-content-register-button:hover {
            background-color: #7e187a;
            border-color: #7e187a;
        }
    /* ##### END REGISTER PAGE ##### */

    .home-content-remember-password {
        color: #1f285a;
        width: 100%;
        font-size: 12px;
        cursor: pointer;
        text-decoration: underline;
    }

    /* Galaxy S III, Iphone 4*/
    @media only screen
    and (-webkit-device-pixel-ratio: 2)
    and (max-width: 480px)
    {
        .main-home {
            width: 100vw;
        }
        .home-content {
            width: 100%;
        }
        .home-content-info-element-img {
            width: 0;
            opacity: 0;
        }
        .home-content-info-element-text {
            width: 65vw;
        }
        .home-content-register, .home-content-login {
            width: 70vw;
        }
    }
    
    /* Iphone 6+/7+/8+, Iphone X*/
    @media only screen
    and (min-width: 375px) 
    and (max-width: 812px) {
        .main-home {
            width: 100vw;
        }
        .home-content {
            width: 100%;
        }
        .home-content-info-element-img {
            width: 0;
            opacity: 0;
        }
        .home-content-info-element-text {
            width: 65vw;
        }
        .home-content-register, .home-content-login {
            width: 70vw;
        }
    }