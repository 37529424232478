.main-dialog-loader {
    pointer-events: none;
}
.main-dialog-loader > div > div {
    height: 100vh;
    width: 100vw;
    margin: 0px;
    background-color: rgba(148, 148, 148, 0.300);
    padding: 0px;
    border: 0px;
    display: contents;
}
.dialog-loader-spinner {
    height: 20vh;
    width: 20vh;
    margin: auto;
}