.ViewMenu-main {
    height: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.ViewMenu-main > .ViewMenu-content {
    margin: 0 2vw;
    height: 100%;
    overflow-y: auto;
}

.ViewMenu-content > h4 {
    color: #1f285a;
    font-size: 16px;
    margin-bottom: 1vh;
}

.ViewMenu-music-menu {
    display: flex;
    flex-direction: column;
}
.ViewMenu-music-menu-text {
    flex: 1;
    margin: 5px 0;
    color: #b3b3b3;
    font-size: 14px;
}

.ViewMenu-music-menu-input {
    flex: 1;
    margin: 5px 0;
}

.ViewMenu-transition-menu {
    width: 100%;
    font-size: 14px;
    color: #b3b3b3;
}

.ViewMenu-transition-menu > div {
    display: flex;
    flex-direction: row;
    margin: 20px 0;
}

.ViewMenu-transition-menu > div > label {
    flex: 1;
    font-size: 14px;
    color: #000000;
}

.ViewMenu-transition-menu > div > input {
    flex: 5;
    color: #000000;
}

.ViewMenu-skin-menu {
    display: flex;
    flex-wrap: wrap;
}

.ViewMenu-skin-menu > img  {
    flex: 1 1;
    height: auto;
    height: min-intrinsic; /* css para safari */
    max-width: 7vw;
    cursor: pointer;
    padding: 1vw;
}

.ViewMenu-skin-menu-selected {
    background-color: #89155950;
    border-radius: 5px;
}
