.main-legal-note {
    color: #212529;
    text-align: center;
    margin: 0 3vh;
    font-size: 0.9rem;
    line-height: 24px;
    max-width: 55vw;
    width: 100%;
    overflow-y: auto;
    position: relative;
    height: 100%;
    /* max-height: 92.5vh; */
}
.main-FAQS {
    color: #1f285a;
    text-align: center;
    margin: 0vh auto 0 auto;
    font-size: 0.9rem;
    line-height: 24px;
    max-width: 55vw;
    width: auto;
    margin-left: 3vw;
    position: relative;
    overflow-y: auto;
    /* height: 92vh; */
}
.main-FAQS > dl {
    margin-right: 0.5vw;
    padding-right: 2.5vw;
    height: 90.5vh;
    overflow-y: auto;
}
.legal-note-header {
    flex-direction: row;
    display: flex;
}
.legal-note-header > div {
    flex: 1;
}
.legal-note-header > img {
    flex: 2;
}
    
.legal-note-header-back {
    width: 50px;
    height: 50px;
    margin: 10px auto auto 10px;
    cursor: pointer;
    text-align: left;
}
    
.legal-note-header-logo {
    width: 250px;
    height: 250px;
    cursor: pointer;
}
    
.legal-note-content {
    text-align: left;
}
    
.legal-note-content > h3 {
    text-align: center;
}
    
.legal-note-titles {
    font-size: 1.7rem;
    letter-spacing: 1px;
    color: #b1357d;
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: normal;
}
    
.legal-note-bottom-page {
    text-align: center;
    font-size: 0.7rem;
    margin: 50px 0 10px 0;
}

.legal-note-table {
    border: 1px solid black;
    margin: 4vh auto;
    text-align: center;
}
