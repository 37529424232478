.contenedor-main{
    text-align: left;
    height: 100vmin;
    font-family: roboto;
    display: none;
    justify-content: center;
 
  }
.h1{
  color: header-color;
  font-size: 20px;
  text-align: left;
  justify-content: left;

  position: relative;
  width: 100%;
  padding-right: 25px;
  padding-left: 35px;
  }

  .h3{
    margin-left: 50px;
    align-items: left;
    text-align: center;
    cursor: pointer;
  }

  .container-faq-1 {

    flex-direction: column;
    justify-content: left;
    align-items: left;
    font-size: 14px;
    text-align: justify;
    text-justify: 650px;
    cursor: pointer; 
  }

    .container-faq-2 {
      font-size: 16px;
      font-family: roboto;
      text-align: left;
      align-items: center;
      justify-content: center;
      text-justify: 650px;
      text-justify: center;
      display: none;
   
    }

    .show-class {
      display: block;
      text-justify: 650px;
      text-justify: center;
    }
    
     .container-faq-1.container-faq-2 {
      
      display:block;
      margin-right: 380px;
      text-justify: 650px;
      text-justify: center;
    }
    

   .dl{
    font-size: 12px;
    text-align: left;

  }  

  .dt{
    text-align: left;
    justify-content: left;
   
  }
 
.dd{
  
  text-align : justify;
  margin: 0px;
  padding: 0px; 
  height:0;

}