.main-ShopPolicyRules {
    font-family: roboto;
    color: #1f285a;
    text-align: left;
    margin: 0 3vh;
    font-size: 0.9rem;
    line-height: 24px;
    max-width: 55vw;
    width: auto;
    position: relative;
}
.main-ShopPolicyRules > h2 {
    text-align: center;
}
.main-ShopPolicyRules > h3 {
    margin-top: 4vh;
    margin-bottom: 1vh;
}
.ShopPolicyRules-text {

}
