@import './InviteFriends.css';
@import './UserVitalsAngels.css';
@import './MenuVistaFriend.css';
@import './SlideMenuOptions.css';

@import  './MyContacts.css';
@import  './PendingAccept.css';
@import  './Requests.css';


.main-vitalangels {
    background: #d8d8d829; /*opacity: 0.16;*/
    display: flex;
    flex-direction: row;
    padding: 6vh 2vw 10vh 6vw;
    height: 76.5vh;
    position: relative;
}
.main-vitalangels > div {
    flex: 1;
    margin: 0 2vw 0 0;
}

.vitalangels-comp-invite-user-vital-angels {
    display: flex;
    flex-direction: column;
}
