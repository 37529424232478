.main-new-section > div > div {
  height: 42vh;
  width: 35vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1f285a;
  font-family: Roboto;
  min-height: 242px;
}
.main-new-section > div > div > * {
  flex: 1;
}
.new-section-title {
  margin: 8vh 0 0 0;
  font-size: 35px;
  color: #7e187a;
  font-weight: bold;
  /* font-family: Roboto; */
}
.new-section-subtitle {
  font-size: 18px;
  margin: 0 0 4vh 0;
}
.new-section-aditional-info {
  font-size: 10px;
  margin: 2vh 0;
}
