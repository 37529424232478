.main-content-custom-dialog > div > div {
  border: 5px solid #7e187a;
}

.generic-dialog > div > div {
  max-width: none !important;
  width: 100vw;
  height: 100vh;
  margin: 0 !important;
  max-height: none !important;
  background-image: linear-gradient(93deg, #891558, #7e187a 52%, #1f285a);
}

.main-content-custom-dialog {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}
.main-content-custom-dialog-children {
  width: 100%;
  font-size: 16px;
  font-family: roboto;
}

.main-content-dialog-preview {
  margin: 15px;
  padding: 0 15px;
  
  display: flex;
  flex-direction: row;
}

.main-content-custom-dialog > div {
  flex: 1;
  text-align: center;
  height: auto;
}

.main-content-custom-dialog > div > img {
  max-height: 125px;
  margin: 5px;
  border-radius: 5px;
  border: 2px solid grey;
}

.img-selected:hover {
  border: 2px solid #1f285a;
}

.selectedImage {
  border: 2px solid #7e187a !important;
  box-shadow: 3px 3px;
}

.bottonera-dialog {
  margin: 0 auto;
  padding: 0.5vh 0;
}

.bottonera-dialog > button {
  flex: 1;
  height: 4vh;
  min-height: 35px;
  width: 155px;
  margin: 0 10px;
  border-radius: 5px;
}
.bottonera-dialog > button:active {
  background-color: rgb(224, 224, 224);
}
.ok-button,
.ko-button {
  color: white;
  border: none;
  background-color: #1f285a;
  cursor: pointer;
}
.ok-button:hover,
.ko-button:hover {
  background: #7e187a;
}
.pop-up-context {
  max-height: 85%;
  margin: 0px 15.9%;
  flex: 7;
  height: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.pop-up-context-pages {
  display: flex;
  flex-direction: row;
  flex: 10;
  pointer-events: none;
}
.pop-up-context-pages > div {
  flex: 7;
  height: 100%;
  max-width: 27vw;
}

/* ###### RESET DIALOG STYLES ######*/
.main-dialog-notify-reset > div > div {
  text-align: center !important;
  padding: 50px 50px 10px;
  height: auto;
  max-height: 80vh;
  overflow-x: hidden;
}
.main-dialog-notify-reset > div > div > label, .main-dialog-notify-mobile > div > div > label {
  margin: 10px 0px;
}
.dialog-notify-reset-content {
  margin: 3vh 1.5vw 4vh;
}
.main-dialog-notify-reset input[type='text'], .main-dialog-notify-mobile input[type='text']{
  text-align: center;
}
.main-dialog-notify-mobile-text {
  margin: 3vh 0;
}

.main-dialog-notify-mobile {
  text-align: center !important;
  padding: 50px 0px 10px 0px;
  height: auto;
  max-height: 80vh;
  overflow-x: hidden;
}

.dialog-notify-reset-label-text {
  font-weight: bold;
  font-family: roboto;
}
.dialog-notify-reset-label-text-to-write {
  margin: 3vh 0;
}

/* ###### END RESET DIALOG STYLES ######*/

/* ###### ALBUM LIST DIALOG ###### */
.main-dialog-list-albums > div > div {
  padding: 5vh 25px 0px;
  height: 33vh;
  width: 50vw;
  min-height: 270px;
  min-width: 600px;
}
.dialog-list-albums-title {
  margin: 0 0 4vh 0;
}

.dialog-list-albums {
  display: flex;
  flex-direction: row;
}
.dialog-list-albums > div {
  margin: 0px 0vw;
  flex: 1;
  min-width: 12vw;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.dialog-list-albums > div > img {
  flex: 5 1;
  margin: auto;
}
.dialog-list-albums > div > label {
  font-family: roboto;
  flex: 1;
  color: #1f285a;
  font-size: 18px;
}

.dialog-list-myalbums {
  display: flex;
  flex-direction: column;
  font-family: roboto;
  margin: 0px 5vw 7vh;
}
.dialog-list-myalbums-select {
  border-radius: 8px;
  border: 1px solid #dfe2e6;
  color: #b3b3b3;
  height: 5.4vh;
  width: -webkit-fill-available;
  padding: 0 1vw;
  margin: 3vh 0 0 0;
}

/* ###### END ALBUM LIST DIALOG ###### */

/* ###### CREATE NEW ALBUM NAME ###### */
.dialog-list-new-album {
  height: auto;
  margin: 1vh 5vw;
  font-family: Roboto;
}
.dialog-list-new-album > div,
.dialog-list-myalbums > div,
.dialog-list-albums-title,
.create-new-collection-title > div {
  font-size: 35px;
  letter-spacing: -0.1px;
  color: #7e187a;
  font-family: roboto;
  font-weight: bold;
}
.dialog-list-new-album-sub-title,
.dialog-list-myalbums-sub-title {
  font-size: 18px;
  color: #1f285a;
  /* font-family: roboto; */
}
.dialog-list-new-album-remember {
  font-size: 10px;
  color: #1f285a;
}
.main-dialog-list-albums > div > div > div > button {
  width: 161px;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
}
.main-dialog-list-albums > div > div > div > button:hover {
  background: #7e187a;
}
/* ###### END CREATE NEW ALBUM NAME ###### */

/* ###### DIALOG CREATE COLECTION ###### */
.main-dialog-create-new-collection > div > div {
  width: 40vw;
  height: 35vh;
  min-height: 308px;
}
.create-new-collection-title {
  height: 18vh;
  max-height: 154px;
  margin: 5vh 5vw;
  font-family: Roboto;
}
/* ###### END DIALOG CREATE COLECTION ###### */

/* ###### DIALOG ALERT GENERATE VIDEO ###### */
.dialog-alert-generate-video > div > div {
  width: 25vw;
  height: 15vh;
}
.dialog-alert-generate-video > div > div > div {
  margin: auto;
}
/* ###### DIALOG ALERT GENERATE VIDEO ###### */

/* ###### DIALOG ALERT GENERATE VIDEO ###### */
.dialog-iframe > div > div {
  min-width: 90vh;
  min-width: -webkit-fill-available;
  height: 85vh;
  overflow: hidden;
}

.dialog-iframe > div > div > div > iframe {
  height: 85.006vh;
  width: inherit;
  border: none;
}
/* ###### DIALOG ALERT GENERATE VIDEO ###### */