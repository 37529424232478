.main-menu-tabs {
    background: #ffffff;
}
.menu-tabs-header {
    margin: 3.5vh 3.7vw 0;
    display: flex;
    flex-direction: row;
}
.menu-tabs-header-btn {
    flex: 1;
    cursor: pointer;
    border-bottom: 1px solid rgb(179, 179, 179, 0.16);
    color: #b3b3b3;
}
.menu-tabs-header-btn > div {
    cursor: pointer;
    margin: 0 0 10px 0
}
.menu-tabs-header-btn-selected {
    color: #1f285a;
    border-bottom: 3px solid #1f285a;
    font-weight: bold;
}
.menu-tabs-header-btn-selected > div {
    color: #1f285a;
}
.menu-tabs-content {
    margin: 2vh 0 0 3vw;
    padding: 0 3vw 0 0;
    text-align: left;
    height: 64.8vh;
    overflow-y: auto;
}
.menu-tabs {
    text-align: left;
    height: 100%;
    flex: 8;
    overflow-y: auto;
    border: solid 1px #e5e8eb;
}
