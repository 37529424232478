.main-template, .main-template-horizontal, .main-template-vertical {
    background-color: transparent;
    height: 100%;
    width: 100%;
    display: flex;
}
.main-template, .main-template-vertical {
    flex-direction: column;
}
.main-template-horizontal {
    flex-direction: row;
}
.main-template > textarea, .main-template-horizontal > textarea {
    color: black;
    resize: none;
}
.main-template-horizontal > *, .main-template-vertical > *  {
    flex: 1;
}

/* CSS Template 1 */
.template1-text-1 {
    background: #fff;
    margin-top: 10px;
    -webkit-flex: 1;
    flex: 1;
    resize: none;
    font-family: roboto;
    font-size: 16px;
    padding: 0.5vh 0;
    border: 1px solid rgb(169, 169, 169, 0);
    text-align: left;
}
.template1-resource-1 {
    background: #fff;
    flex: 1;
    background-color: grey;
    flex: center;
    display: flex;
    flex-direction: row;
    justify-content: left;
}
.template1-text-2, .template-6-text {
    background: #fff;
    border: 1px solid rgb(169, 169, 169, 0);
    flex: 1;
    padding: 1vh;
    resize: none;
    font-family: roboto;
    font-size: 16px;
}
.template8-title-1 {
    background: #fff;
    color: #1f285a !important;
    border: 1px solid rgb(169, 169, 169, 0);
    flex: 0.1;
    resize: none;
    text-align: center;
    font-weight: bold;
    font-family: roboto;
    font-size: 30px;
    min-height: 50px;
    flex: 0.1;
    margin:  0 0 1vh;
}
/* END CSS Template 1 */

/* CSS Template 4 */
.dropContxt-empty-img-template4-full > div {
    margin-top: 30vh;
}
/* END CSS Template 4 */

/* CSS Template 5 */
.dropContxt-empty-img-template5-full > div {
    margin-top: 34vh;
}
/* END CSS Template 5 */

/* CSS Template 6 */
.template-6-img-text {
    /* background: #fff; */
    flex: 1;
    display: flex;
    flex-direction: row;
    height: inherit;
    max-height: 50%;
}
.template-6-text {
    max-width: calc(50% - 1vw);
    margin: 0;
    padding: 0.5vh 0.5vw;
}

.dropContxt-empty-img-template6-half > div {
    margin-top: 17vh
}
/* END CSS Template 6 */

/* CSS Template 7 */
.template-7-img-img {
    margin: 0 0 0 0.25vw;
    display: flex;
    flex-direction: column;
    /* background: #fff; */
}
.template-7-img-img > div {
    height: 50%;
}
.dropContxt-empty-img-template7-full > div {
    margin-top: 34vh;
}
.dropContxt-empty-img-template7-half > div {
    margin-top: 17vh;
}
/* END CSS Template 7 */

/* CSS Template 8 */
/* END CSS Template 8 */

.template-preview {
    background-color: transparent;
}
.template-preview:hover {
    border: 1px solid transparent;
}
