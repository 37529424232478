@import './DocumentPreviewMiniature.css';

.main-DocumentsHome {
  height: 81.5vh;
  font-family: Roboto;
  color: #1f285a;
  text-align: left;
  padding: 5.5vh 5.5vw;
  background-color: #d8d8d829;
  position: relative;
}
.main-DocumentsHome > h3 {
  margin: 0 0 2vh 0;
  color: #92117e;
}
.DocumentsHome-content {
    display: flex;
    flex-direction: row;
    height: 76vh;
}
    /*COMPONENT DOCUMENTS WITH TABS*/
    .Testamento-content-type-docs {
        flex: 2.5;
        margin: 0 2vw 0 0;
        display: flex;
        flex-direction: column;
    }
    .Testamento-content-type-docs-tabs {
        border-bottom: 0px;
        width: 50%;
        flex: 0.5;
        display: flex;
        flex-direction: row;
    }
    .Testamento-content-type-docs-tabs-btn {
        background-color: #d8d8d829;
        color: #1f285b8a;
        border: solid 1px #e5e8eb;
        border-bottom: 0px;
        /* clip-path: polygon(80% 0%, 100% 100%, 0% 100%, 0% 0%); */
        border-radius: 10px 10px 0px 0;
        flex: 1;
        cursor: pointer;    
    }
    .Testamento-content-type-docs-tabs-btn > div {
        /* color: #1f285b8a; */
        font-size: 15px;
        margin: auto;
        display: flex;
        width: fit-content;
        cursor: pointer;
        padding: 1vh 0;
    }
    .Testamento-content-type-docs-tabs-content, .Otros-main {
        background-color: #ffffff;
        border-top: 0px;
        flex: 10;
        display: flex;
        flex-direction: column;
        height: calc(100% - 1px );
    }
    .Testamento-content-type-docs-tabs-content-import {
        flex: 1.221;
        display: flex;
        flex-direction: row;
    }
    .Testamento-content-type-docs-tabs-content-import-others {
        flex: 1.4;
        display: flex;
        flex-direction: row;
    }
    .Testamento-content-drag-drop-element {
        flex: 1;
        margin: 6vh 1.5vw 6vh 3vw;
        display: flex;
        flex-direction: column;
    }
    .Testamento-content-drag-drop-element > div{
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background-color: #f3eaf3;
    }
    .Testamento-content-type-docs-tabs-content-drop-file {
        flex: 1;
        margin: 6vh 1.5vw 6vh 3vw;
        /* border: dashed 1px #872883; */
        border-radius: 5px;
        background-color: #f3eaf3;
        display: flex;
        flex-direction: column;
        /* opacity: 0.3; */
    }
    .Testamento-content-type-docs-tabs-content-drop-file > img {
        margin: auto auto 0 auto;
        height: 30px;
        width: 30px;
    }
    .Testamento-content-type-docs-tabs-content-drop-file > div {
        margin: 0 auto auto auto;
        color: #92117e;
        font-size: 15px;
    }
    .Testamento-content-type-docs-tabs-content-drop-file > label {
        margin: 0 auto auto auto;
        color: #1f285a;
        font-size: 12px;
    }
    .Testamento-content-type-docs-tabs-content-select-file {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin: auto 3vw auto 1.5vw;
    }
    .Testamento-content-type-docs-tabs-content-select-file > label {
        letter-spacing: 0.03px;
        color: #1f285a;
        font-size: 15px;
        font-weight: bold;
        margin: auto 0 0 0;
    }
    .Testamento-content-type-docs-tabs-content-select-file > div {
        color: #1f285a;
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: 0.02px;
        margin: 2vh 0;
    }
    .Testamento-content-type-docs-tabs-content-select-file-btn  {
        border-radius: 8px;
        border: solid 1px #1f285a;
        background-color: #ffffff;
        color: #1f285a;
        cursor: pointer;
        width: 13.3vw;
        height: 3vh;
        min-width: 130px;
        padding: 1vh 0;
        margin: 0 0 auto 0;
        font-family: Roboto;
        font-weight: bold;
    }
    .Testamento-content-type-docs-tabs-content-select-file-btn > label, .uploadDocOnSection-input > label {
        color: #1f285a;
        height: 2vh;
        font-size: 14px;
        cursor: pointer;
        text-align: center;
        width: 13.3vw;
        position: absolute;
        padding: 0.5vh 0;
    }
    .Testamento-content-type-docs-tabs-content-files {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin: 0 3vw;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .Testamento-content-type-add-section {
        border-top: 1px solid #e5e8eb;
        height: 4vh;
        margin: auto 0;
        display: flex;
        cursor: pointer;
    }
    .Testamento-content-type-add-section > img {
        height: 12px;
        width: 12px;
        margin: auto 0px auto 50px;
    }
    .Testamento-content-type-add-section > div {
        font-family: Roboto;
        font-size: 14px;
        line-height: 1;
        color: #1f285a;
        margin: auto 0px auto 10px;
    }
    .Testamento-content-type-docs-tabs-content-file-title {
        flex: 1;
        font-weight: bold;
        margin: 1vh 0;
        display: flex;
        font-size: 18px;
        max-height: fit-content;
    }
    .Testamento-content-type-docs-tabs-content-files-carrousel {
        flex: 4;
        overflow-x: auto;
        margin: 0 0 2px 0;
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 57vw;
    }
    .Testamento-content-type-docs-tabs-content-files-carrousel > * {
        flex: 1;
        flex-wrap: row;
        margin: auto 0;
        max-width: fit-content;
    }
    /*END COMPONENT DOCUMENTS WITH TABS*/

.DocumentsHome-content-template-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 4.47vh;
}
.DocumentsHome-content-template {
  background-color: #ffffff;
  border: solid 1px #e5e8eb;
  flex: 1;
  margin: 0 0 2vh 0;
  display: flex;
  flex-direction: column;
  padding: 0 2.5vw;
  /* opacity: 0.4; */
}
.DocumentsHome-content-template > h3 {
  margin: 4vh 0 0 0;
  color: #92117e;
}
.DocumentsHome-content-template > label {
  color: #1f285a;
  font-size: 14px;
  font-weight: bold;
  margin: auto 0;
}
.DocumentsHome-content-template > select {
  border: 0px;
  font-size: 14px;
  color: #c4c4c4;
  border-bottom: solid 1px #1f285a;
  margin: auto;
  width: 75%;
  min-width: 275px;
  cursor: pointer;
  padding: 1vh 0;
}
.DocumentsHome-content-template > select > option {
  cursor: pointer;
}
.DocumentsHome-content-template > button {
  color: #ffffff;
  background-color: #1f285a;
  border: 1px solid #1f285a;
  border-radius: 8px;
  height: 5vh;
  width: 50%;
  min-width: 150px;
  margin: auto;
  font-weight: bold;
}
.DocumentsHome-content-template > button:hover:not(:disabled) {
  background-color: #ffffff;
  color: #1f285a;

  cursor: pointer;
}
.DocumentsHome-content-template > button:disabled {
  background-color: #808080;
  border-color: #808080;
  color: white;
}
.DocumentsHome-content-info {
  background-color: #ffffff;
  border: solid 1px #e5e8eb;
  flex: 1;
  margin: 2vh 0 0 0;
  display: flex;
  flex-direction: column;
}
.DocumentsHome-content-info > div {
  margin: 5vh 0 0 0;
}
.DocumentsHome-content-info > div > h4 {
    margin: 0 auto 5vh auto;
}
.DocumentsHome-content-info-text {
    font-size: 12px;
    font-family: roboto;
    margin: 2vh 2vw 4vh 2vw;
}

/* Otros docs > section */

.main-section {

}
.section-header {
    color: #1f285a;
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px #e2e5e9;
}
.section-header-title {
    flex: 5;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    margin: auto 0;
    display: flex;
}
.section-header-img {
    flex: 1;
    margin: auto 0;
    display: flex;
    flex-direction: row;
    max-width: fit-content;
}
.section-header-img > img {
    width: 15px;
    height: 15px;
    display: flex;
    cursor: pointer;
}
.section-header-img-arrow {
    margin: 0 0 0 auto;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    transform: rotate(180deg);
}
.section-header-img-arrow-show {
    transform: rotate(0deg);
}
.section-header-img-elipsis {
    margin: 0 0.5vw 0 1vw;
}
.section-content {
    width: 100%;
    height: 0;
    -webkit-transition: height 0.5s, visible 0.5s;
    -moz-transition: height 0.5s, visible 0.5s;
    transition: height 0.5s, visible 0.5s;
    visibility: hidden;
}
.section-content-show {
    height: 17vh;
    visibility: visible;
    display: flex;
    flex-direction: row;
    -webkit-transition: height 0.5s;
    -moz-transition: height 0.5s;
    transition: height 0.5s;
}
.section-slide-menu {
    visibility: hidden;
    /* position: absolute; */
    /* margin: -4vh 56vw; */
}

.section-slide-menu-visible {
    visibility: visible;
}
