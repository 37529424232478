@font-face {
  font-family: 'roboto';
  font-style: normal;
  font-weight: 400;
  src: url('..//typeFont//font-file-robotoRegular.woff');
}

.App {
  text-align: center;
  font-family: roboto;
  width: 100%;
  cursor: default;
}

/* height: 40vmin; */

/* ####### CSS IMPORTS #######*/
@import './MainViews/Home.css';
@import './MainViews/Settings.css';
@import './MainViews/PrinterMenu.css';
@import './componentStyles/Carrousel.css';
@import './componentStyles/Botonera.css';
@import './componentStyles/PhotoBook.css';
@import './componentStyles/TemplateStyles.css';
@import './componentStyles/CustomInput.css';
@import './componentStyles/ViewMenu.css';
@import './componentStyles/ImagesEditor.css';
@import './componentStyles/ProfileContainer.css';
@import './componentStyles/MenuTabs.css';
@import './componentStyles/BarUsedStorage.css';
@import './Dialogs/CustomDialog.css';
@import './Dialogs/PreviewDialog.css';
@import './Dialogs/SlideShotDialog.css';
@import './Dialogs/FlotingDialog.css';
@import './Dialogs/InfoUploadImgDialog.css';
@import './Dialogs/SuscriptionDialog.css';
@import './Dialogs/UploadDocOnSection.css';
@import './Dialogs/NewSectionDialog.css';
@import './Dialogs/BuyPrinterDialog.css';
@import './Dialogs/LoaderDialog.css';
@import './VitalAngels/VitalAngels.css';
@import './Documents/DocumentsHome.css';
@import './VitalBook.css';
@import './MyApps.css';
@import './LegalNotes.css';
@import './Faqs.css';
@import './Header.css';
@import './HeaderBook.css';
@import './ShopPolicyRules.css';
@import './LegalInfo.css';
@import 'OkKoStyles.css';

/*
@import './Dialogs/Faqs.css';
*/

/* CUSTOM DIALOG */
.custom-dialog {
  border-radius: 5px;
  position: absolute;
  height: 20vh;
  width: 15vw;
  background-color: white;
}

/* INPUT BUTTON CUSTOM */
.upp-image-btn-local {
  background-color: #1f285a;
  cursor: pointer;
  border-radius: 5px;
  margin: 20px auto 20px 0px;
}

/* input[type=text] {
  border-color: #333;
} */

/* TEXT AREA CUSTOM */
textarea {
  border: 0px;
}

textarea:hover {
  border: 1px solid rgb(169, 169, 169);
  /* border-radius: 5px; */
}

button {
  outline: none !important;
}

.combo-box-idioma {
  height: 100px;
  width: 100px;
  background-color: #7e187a #ffffff;
  color: #000000;
  position: absolute;
}

/* SCROLLBAR CUSTOM */
.scrollbar, 
.scrollbar > div > div {
  scrollbar-color: #92117e;
}
/* #style-3:: */
.scrollbar::-webkit-scrollbar-track,
/* .scrollbar::-moz-horizontal-scrollbar, */
.scrollbar > div > div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.scrollbar::-webkit-scrollbar,
/* .scrollbar::-moz-horizontal-scrollbar, */
.scrollbar > div > div::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #f5f5f5;
}
.scrollbar::-webkit-scrollbar-thumb,
/* .scrollbar::-moz-horizontal-scrollbar, */
.scrollbar > div > div::-webkit-scrollbar-thumb {
  background-color: #92117e;
}
.hidden-scrollbar {
}
/* #style-3:: */
.hidden-scrollbar::-webkit-scrollbar-track /*, .hidden-scrollbar > div > div::-webkit-hidden-scrollbar-track */ {
  -webkit-box-shadow: inset 0 0 6px transparent;
  background-color: transparent;
}
.hidden-scrollbar::-webkit-scrollbar /*, .hidden-scrollbar > div > div::-webkit-hidden-scrollbar */
/*, .hidden-scrollbar::-moz-horizontal-scrollbar  */
{
  width: 4px;
  height: 4px;
  background-color: transparent;
}
.hidden-scrollbar::-webkit-scrollbar-thumb /*, .hidden-scrollbar > div > div::-webkit-hidden-scrollbar-thumb */ 
/* , .hidden-scrollbar::-moz-horizontal-scrollbar */
{
  background-color: transparent;
}
