
.custom-input-main {
    margin-top: 1vh;
}

.custom-input-title {
    text-align: center;
    width: 100%;
    font-size: 16px;
}

.custom-input-element {
    border-radius: 8px;
    border: 1px solid #7e187a;
    padding: 0 1.5vh;
    margin: 1vh 0 1vh 0;
    height: 4vh;
    font-size: 14px;
    text-align: center;
    cursor: auto;
    outline: none !important;
    width: -moz-available;
    width: -webkit-fill-available;
}

.custom-input-element-error > input {
    border: 1px solid #ff3e3e !important;
}
