.main-slide-shot-dialog {
    height: fit-content;
}
.main-slide-shot-dialog > div {
}

.main-slide-shot-dialog > div > div {
    max-width: none !important;
    width: 100vw;
    margin: 0 !important;
    max-height: none !important;
    border: 0;
    background-color: #ffffff;
}
.main-slide-shot-dialog > div > div > div {
    background-color: #000000cc;
    display: flex;
}
.content-slide-shot-dialog {
    height: 100vh;
    margin: 0px auto;
}
.content-slide-shot-dialog > img {
    object-fit: cover;
    height: 100vh;
}
.slide-shot-dialog-element {
    background-color: rgba(255, 255, 255, 0.205);
}
.slide-shot-dialog-element > img {
    max-height: 85vh;
    height: -webkit-fill-available;
}
.slide-shot-dialog-element-label {
    background-color: rgba(255, 255, 255, 0.205);
}
.content-slide-shot-dialog > label {
    height: 100%;
    background-color: rgba(255, 255, 255, 0.205);
}
.slide-shot-dialog-botonera:hover {
    opacity: 1;
}
.slide-shot-dialog-botonera {
    opacity: 0;
    width: 100%;
    margin: 0px auto;
    position: absolute;
    height: 10vh;
    bottom: 0vh;
    padding: calc(45% - 10vh) 0 0 0;
    margin: 0 auto;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}
.slide-shot-dialog-botonera > div {
    height: 7vh;
    display: flex;
    width: fit-content;
    flex-direction: row;
    margin: auto;
    border-radius: 8px;
    background-image: linear-gradient(98deg, #881558cc, #7c1778cc, #1f285bcc);
}
.slide-shot-dialog-botonera > div > div {
    flex: 1;
    margin: auto;
}
.slide-shot-dialog-botonera > div > div > img {
    height: 20px;
    width: 20px;
    cursor: pointer;
    margin: auto 20px;
}
