.main-legalInfo {
    text-align: center;
    position: relative;
    color: #1f285a;
}
.main-legalInfo > h3 {
    color: #92117e;
}
/* 
.main-legalInfo > div {
    cursor: pointer;
} */

.legalInfo-context {
    display: flex;
    flex-direction: row;
}
.legalInfo-context-menu {
    flex: 1;
    text-align: left;
    margin: 0 0 0 2vw;
}
.legalInfo-context-menu > div {
    margin: 1.5vh 0;
    cursor: pointer;
}
.legalInfo-context-menu-select {
    font-weight: bold;
    color: #92117e;
}
.legalInfo-context-view {
    flex: 4;
    margin: 0 auto;
    height: 92.5vh;
    overflow-y: auto;
}
