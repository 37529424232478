.main-content-dialog > div > div {
    max-width: none !important;
    width: 100vw;
    height: 100vh;
    margin: 0 !important;
    max-height: none !important;
    background-color: #f7f7f7;
    border: none;
    border-radius: 0px;
}

.main-content-dialog-preview {
    margin: 15px;
    padding: 0 15px;
    height: 62vh;
    display: flex;
    flex-direction: column;
}
.main-content-dialog-preview-pages {
    display: flex;
    flex-direction: row;
    flex: 11;
    pointer-events: none;
}
/* .album-context-pages {
    display: flex;
    flex-direction: row;
    flex: 10;
} */

/* .main-content-dialog-preview > div { */
.main-content-dialog-preview-pages > div {
    flex: 1;
    text-align: center;
    /* height: 100%; */
}

.main-content-dialog-preview-element {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
}

.main-content-dialog-preview-img {
    height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
}

.album-context-pages-preview {
    display: flex;
    flex-direction: row;
    height: inherit;
    /* margin: 0 0 7.5vh 0; */
    margin: 1vh 0 7vh 0;
}

.album-context-pages-preview > div {
    flex: 1;
    max-width: 41%;
}
