/* FILLABLE DOCUMENT */

div.FillableDocument {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  text-align: initial;
  position: relative;
  width: 100%;
}

div.FillableDocument > * {
  background-color: white;
  margin: 0px 10px;
  padding: 0px 25px;
}

/* FORM */

form.FillableDocument__Form * {
  user-select: none;
}

form.FillableDocument__Form > * {
  display: block;
}

form.FillableDocument__Form.pad {
  padding: 0 15px;
}

div.FillableDocument
  form.FillableDocument__Form
  > div.FillableDocument__Form__form-controls {
  margin: 10px 0;
}

form.FillableDocument__Form > span {
  width: 100%;
  margin: 10px 0;
}

div.FillableDocument > div.FillableDocument__Form-container {
  display: flex;
  flex-direction: column;

  width: 100%;

  max-height: 90vh;
  overflow-y: auto;
}

div.FillableDocument form.FillableDocument__Form > span > h4 {
  color: #92117e;
}

form.FillableDocument__Form input:not([type='radio']) {
  width: 100%;

  border: none;
  border-bottom: 2px solid #92117e;
  background-color: transparent;

  outline: none;

  margin-bottom: 25px;
}

form.FillableDocument__Form > span select {
  width: 60%;

  background-color: #efefef;

  border: 2px solid #92117e;
  border-radius: 2px;

  outline: #92117e;

  text-decoration: #92117e;
}

form.FillableDocument__Form div.FillableDocument__Form__ListInput > div {
  padding-top: 15px;
}

form.FillableDocument__Form
  div.FillableDocument__Form__ListInput
  > div.FillableDocument__Form__ListInput-wrapper {
  position: relative;
}

form.FillableDocument__Form
  div.FillableDocument__Form__ListInput
  > div.FillableDocument__Form__ListInput-wrapper
  > img {
  position: absolute;
  top: -5px;
  right: -5px;

  padding: 10px;
  border-radius: 50%;

  background-color: transparent;
  transition: background-color 0.4s;
}

form.FillableDocument__Form
  div.FillableDocument__Form__ListInput
  > div.FillableDocument__Form__ListInput-wrapper
  > img:hover {
  background-color: #cecece;
  cursor: pointer;
}

form.FillableDocument__Form div.FillableDocument__Form__ListInput > div + div {
  border-top: 2px dashed #808080;
}

form.FillableDocument__Form
  div.FillableDocument__Form__ListInput
  > div:nth-child(2n + 1) {
  background-color: #efefef;
}

form.FillableDocument__Form button.FillableDocument__Form__ListInput-add {
  width: 100%;

  text-align: center;
  border: 1px dashed #a0a0a0;

  padding: 5px;
  margin: 5px 0;

  cursor: pointer;
}

form.FillableDocument__Form button.FillableDocument__Form__ListInput-add:hover {
  background-color: #efefef;
}

form.FillableDocument__Form > div.FillableDocument__Form__form-controls {
  display: flex;
  justify-content: space-around;
}

form.FillableDocument__Form
  > div.FillableDocument__Form__form-controls
  > button {
  padding: 5px 10px;

  border: 2px solid #1f285a;
  border-radius: 10px;

  font-weight: bold;

  min-width: 40%;
}

form.FillableDocument__Form
  > div.FillableDocument__Form__form-controls
  > button:disabled {
  border-color: #808080;
}

form.FillableDocument__Form
  > div.FillableDocument__Form__form-controls
  > button:hover:not(:disabled) {
  cursor: pointer;
}

form.FillableDocument__Form
  > div.FillableDocument__Form__form-controls
  > button.FillableDocument__Form__form-controls__button-previous {
  background-color: white;
}

form.FillableDocument__Form
  > div.FillableDocument__Form__form-controls
  > button.FillableDocument__Form__form-controls__button-previous:disabled {
  color: #808080;
}

form.FillableDocument__Form
  > div.FillableDocument__Form__form-controls
  > button.FillableDocument__Form__form-controls__button-next {
  background-color: #1f285a;
  color: white;
}

form.FillableDocument__Form
  > div.FillableDocument__Form__form-controls
  > button.FillableDocument__Form__form-controls__button-next:disabled {
  background-color: #808080;
}

.FillableDocument__Form__form-dowload {
  margin: 5vh auto auto 0;
  border-radius: 5px;
  height: 3.5vh;
  width: fit-content;
  color: white;
  border: 1px solid transparent;
  background-color: #92117e;
  cursor: pointer;
}
.FillableDocument__Form__form-dowload:disabled:hover {
  background-color: #ffffff;
  color: #92117e;
  border: 1px solid #92117e;
}

.FillableDocument__Form__form-dowload:disabled:hover {
  background-color: #808080;
  color: #ffffff;
  border: 1px solid #808080;
}

.FillableDocument__Form__form-dowload:disabled {
  background-color: #808080;
  cursor: not-allowed;
}

.Form__title {
  color: #92117e;
}

.Form__description {
  font-weight: normal;
}

.Form__title,
.Form__description {
  margin: 10px 15px;
  user-select: none;
}

div.FillableDocument div.FillableDocument__Document {
  height: 92.5vh;
  overflow-y: auto;
}

div.FillableDocument span[class*='FillableDocument__Document__field-'] {
  color: white;
  border-radius: 3px;
}
div.FillableDocument span.FillableDocument__Document__field-filled {
  background-color: rgba(146, 17, 126, 0.35);
}
div.FillableDocument
  span.FillableDocument__Document__field-filled:not(.FillableDocument__Document__field-extension) {
  cursor: pointer;
}
div.FillableDocument span.FillableDocument__Document__field-selected,
div.FillableDocument
  span[class*='FillableDocument__Document__field']:not(.FillableDocument__Document__field-extension):hover {
  background-color: rgba(146, 37, 130, 0.75);
}

div.FillableDocument input,
div.FillableDocument div.react-datepicker-wrapper,
div.FillableDocument div.react-datepicker-wrapper > * {
  width: 100%;
}
