.main-container-carrousel {
    background-color: grey;
    height: 40vh;
    width: 100%;
    margin: 20px 0;
    overflow-x: auto;
    
    flex: center;
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.containe-time-empty {
    align-content: center;
    border-style: dashed;
    border-width: 3px;
    border-color: #c3c3c3;
    border-radius: 5px;
}
.containe-time-empty > img {
    align-content: center;
    width: 25px;
    margin-top: 25%;
}

.container-time {
    width: 100px;
    height: auto;
    border-radius: 5px;
}

.container-time > img {
    width: 100px;
    height: auto;
    border-radius: 5px;
}

.container-img-carrousel {
    max-width: 100px;
    max-height: 100px;
    background-color: red;
    margin: 0 1px;
    flex: 1;
} 
