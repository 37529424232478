.main-floting-dialog {
    position: absolute;
    z-index: 99999;
    width: auto;
	padding: 15px 20px;
	color: white;

    border-radius: 5px;
    left: 50%;
    transform: translate(-50%, 0);
    top: 10%;
	opacity: 1;
	display: flex;
	flex-direction: row;

	text-align: center;
	vertical-align: middle;
}

.main-floting-dialog > div {
	margin-left: 10px;
	text-align: center;
	vertical-align: middle;
	font-size: 16px;
	font-family: roboto;
}

.main-floting-dialog > img {
	width: 20px;
	height: 20px;
}

.error-dialog {
	background-color: #ff3e3e;
}

.ok-dialog {
	background-color: #24c454;
}

/* ### slideDown animation ### */
.slideDown {
	animation-name: slideDown;
	-webkit-animation-name: slideDown;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;	

	visibility: visible !important;						
}

@keyframes slideDown {
	0% {
		transform: translate(-50%, -100%);
	}
	50%{
		transform: translate(-50%, 8%);
	}
	65%{
		transform: translate(-50%, -4%);
	}
	80%{
		transform: translate(-50%, 4%);
	}
	95%{
		transform: translate(-50%, -2%);
	}			
	100% {
		transform: translate(-50%, 0%);
	}		
}

@-webkit-keyframes slideDown {
	0% {
		-webkit-transform: translate(-50%, -100%);
	}
	50%{
		-webkit-transform: translate(-50%, 8%);
	}
	65%{
		-webkit-transform: translate(-50%, -4%);
	}
	80%{
		-webkit-transform: translate(-50%, 4%);
	}
	95%{
		-webkit-transform: translate(-50%, -2%);
	}			
	100% {
		-webkit-transform: translate(-50%, 0%);
	}	
}