.main-BuyPrinter > div > div {
    height: max-content;
    min-width: 35vw;
    margin: 0;
}
.BuyPrinter-title {
    color: #7e187a;
}

.BuyPrinter-content-form {
    width: 80%;
    margin: auto;
}
