
.main-slide-menu-options {
    height: auto;
    width: auto;
    padding: 3vh 6vh 13.8vh 6vh;
    border-radius: 4px;
    position: absolute;
    background: #8080803d;
}
.slide-menu-options-context, .slide-menu-section-context {
    height: fit-content;
    width: 10vh;
    box-shadow: 0 2px 4px 0 rgba(153, 153, 153, 0.5);
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 1vh 0;
    text-align: center;
}
.slide-menu-options-context {
    margin: 1vh auto;
}
.slide-menu-options-context > div, .slide-menu-section-context > div {
    cursor: pointer;
    padding: 0.5vh 0;
    flex: 1;
    color: #1f285a;
    font-size: 11px;
    height: fit-content;
    width: 100%;
}
.slide-menu-options-context > div:hover, .slide-menu-section-context > div:hover {
    color: #fff;
    background-color: #7e187a;
}
.main-slide-menu-options-docs {
    height: 17vh;
    width: 8.5vw;
    /* position: absolute; */
    background: #8080803d;
}
/* SlideMenuSection */
.main-slide-menu-section {
    height: auto;
    width: auto;
    padding: 2vh 2.2vh 3.3vh 2.2vh;
    position: absolute;
}
.main-dialog-slide-menu-section {
    top: 21vh !important;
    left: 32vw !important;
}
.main-dialog-slide-menu-section > div > div {
    height: auto;
    width: auto;
    border: none;
    box-shadow: 0 2px 4px 0 rgba(153, 153, 153, 0.5);
}
.main-dialog-slide-menu-section > div > div > div {
    margin: 0;
}
