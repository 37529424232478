.main-img-book {
    border: 1px solid black;
    height: 70vh;
    width: 120vh;
    text-align: center;
}

.draggable-img-container {
    /* height: 100%; */
    /* border-radius: 5px; */
    max-width: 100%;
    object-fit: cover;
}

.dropContxt-empty-img {
    color: #fff;
    font-size: 12px;
}
.dropContxt-empty-img > div {
    width: fit-content;
    margin: 22vh auto auto auto;
    display: flex;
}

.draggable-container {
    height: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid rgb(169, 169, 169, 0);
    background: #80808031;
}

.draggable-container:hover {
    border: 1px solid rgb(169, 169, 169);
    /* border-radius: 5px; */
}

.draggable-container:hover > .menu-edit-img {
    visibility: visible;
    /* transition: all .5s ease-in-out; */
    height: 30px;
}

.image-editor-edit {
    cursor: pointer;
}
.draggable-container:hover > .menu-edit-img > img, .draggable-container:hover > .image-editor-edit {
    visibility: visible;
    height: 25px;
}
