.printerMenu-main {
    overflow-y: auto;
    display: flex;
    height: 100%;
    flex-direction: column;
}
.printerMenu-main > .printerMenu-main-content {
    margin: 0 2vw;
    overflow-y: auto;
    margin: 0 2vw;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.printerMenu-main-btn {
    cursor: pointer;
    background: #1f285a;
    color: #ffffff;
    width: 100%;
    height: auto;
    margin: auto 0px 0px 0px;
    text-align: center;
    padding: 1.5vh 0;
    font-size: 14px;
}