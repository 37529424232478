.load-code-view {
  height: 100vh;
  width: 100vw;
  position: absolute;
  color: #ffffff;
  background-image: linear-gradient(98deg, hsla(325, 73%, 31%, 0.6), hsla(302, 69%, 29%, 0.6), hsla(231, 49%, 24%, 0.6));
  background-color: white;
  z-index: 5;
  margin: -7.5vh 0 0 0;
  font-family: Roboto;
  font-size: 50px;
}

.myapp-main-container {
  height: 92.55vh;
  display: flex;
}

#myVideo {
  position: inherit;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.profile-container-btn-facebook, .profile-container-btn-instagram, .menu-container-btn-instagram, .upp-image-btn-local, .menu-container-btn-facebook, .btn-upload-local-photos-icon {
  width: 220px;
  height: 40px;
  border-radius: 5px;
  font-family: Roboto;
  color: white;
  margin: 20px auto 20px auto;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: 0px;
}

.profile-container-btn-facebook, .menu-container-btn-facebook {
  background-color: #3c5a99;
}
.profile-container-btn-instagram, .menu-container-btn-instagram {
  background-color: #f46f30;
}
.menu-container-btn-instagram, .menu-container-btn-facebook {
  margin: 20px auto 20px 0px;
}

.listapps-container-title-apps { 
  display: flex;
  position: absolute;
  flex-direction: column;
  height: inherit;
  width: 100%;
  background-image: linear-gradient(98deg, hsla(325, 73%, 31%, 0.6), hsla(302, 69%, 29%, 0.6), hsla(231, 49%, 24%, 0.6));
}
.listapps-container-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  height: auto;
  margin: 0px 30vw;
  flex: 1.5;
}
.listapps-container-options-disable > div > img {
  opacity: 0.4;
}
.listapps-container-options-lists {
  width: 100%;
}
.listapps-container-options > div {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.listapps-container-option { 
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 10vw;
  margin: 0px auto;
}
.listapps-container-title {
  flex: 1 1;
  justify-content: center;
  flex-direction: column;
  display: flex;
  font-size: 35px;
  color: white;
}

.listapps-container-title-user {}
.listapps-container-title-welcome {
  font-size: 18px;
}

.fila_1{
  flex-grow: 3;
  flex-basis: auto;
}

.fila_2{
  flex-grow: 3;
  flex-basis: auto;
}

.listapps-container-apps {
  cursor: pointer;
  width: 100px;
  height: 100px;
  /* border-radius: 50%;
  background-color: #7e187a; */
  position: relative; /*<- OJOOOOOO si le quitamos esto no sé porque no podemos clicar encima*/

  display: flex;
  justify-content: center;
  background: white;
  border-radius: 100%;
  -webkit-transition: width 0.75s, height 0.75s; /* For Safari 3.1 to 6.0 */
  transition: width 0.75s, height 0.75s;
}
.listapps-container-apps:hover {
  width: 120px;
  height: 120px;
  -webkit-transition: width 0.75s, height 0.75s; /* For Safari 3.1 to 6.0 */
  transition: width 0.75s, height 0.75s;
}
.listapps-container-apps > img {
  width: 50px;
  height: 50px;
  margin: auto;
}
.listapps-container-titleapps {
  margin-top: 5px;
  color: white;
  font-size: 18px;
  font-weight: 500;
  cursor: context-menu;
  opacity: 1;
  -webkit-transition: opacity 0.5s; /* For Safari 3.1 to 6.0 */
  transition: opacity 0.5s;
}
.listapps-container-titleapps-infotext {
  margin: 125px 0 0 0;
  color: #ffffff;
  position: absolute;
  height: fit-content;
  width: 15vw;
  font-size: 15px;
  opacity: 0;
  z-index: 1;
  -webkit-transition: opacity 0.5s; /* For Safari 3.1 to 6.0 */
  transition: opacity 0.5s;
}
.listapps-container-apps:hover ~ .listapps-container-titleapps {
  opacity: 0;
}
.listapps-container-apps:hover ~ .listapps-container-titleapps-infotext{
  opacity: 1;
  height: 0px;
}
.time-container {
  flex: 1;
  display: flex; /*es item y contenedor de otros items*/
  flex-direction: column;
}
.time-container-clock-date {
  flex: 1;
  display: flex; /*contenedor de item clock y date*/
  flex-direction: column;
  margin-top: 20px;
}
.time-container-clock {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  color: #000000;
}
.time-container-date {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
}
.time-container-language {
  flex:1;
  display: flex; /*contenedor del item para seleccionar language*/
  flex-direction:column-reverse;
}
.time-container-language-selector {
  display: inline-block;
  transform: rotate(90deg);

  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.9);
}
.time-container-selector {
  align-self: center;
  margin-bottom: 20%;
  width: 33%;
}

/*CUSTOM INPUT*/
.editIcon{
  height: 10px;
  width: 10px;
}


.main-family-tree {
  display: flex;
  flex-direction: column;
  position: relative;
}

.main-family-tree > iframe {
  height: 92.5vh;
  border: 0px;
}