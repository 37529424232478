
.settings-main {
    overflow-y: auto;
    display: flex;
    height: 100%;
    flex-direction: column;
}

.settings-main > .settings-main-content {
    margin: 0 2vw;
    overflow-y: auto;
    margin: 0 2vw;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 1vh 0;
}
.settings-main-content > h4 {
    color: #1f285a;
    margin-bottom: 1vh;
    font-size: 16px;
}
.settings-main-input {
    text-align: left;
}

.settings-main-input-delete {
    margin: 0 auto; 
}
.settings-main-input > div, .settings-main-input-delete > div {
    color: #1f285a;
}
.settings-main-input > input, .settings-main-input-delete > input{
    border-color: #dfe2e6;
    opacity: 0.8;
    text-align: left;
}

.settings-main-input > input:focus, .settings-main-input-delete > input:focus{
    border-color: #7e187a;
    opacity: 1;
}

.settings-main-input-error > input {
    border-color: rgb(255, 78, 78);
}

.settings-main-text-reset, .settings-main-text-del-colection {
    font-size: 14px;
    color: #9b9b9b;
}
.settings-main-text-reset > b {
    color: #1f285a;
}
.settings-select-colection {
    border: solid 1px #dfe2e6;
    border-radius: 8px;
    padding: 0 1.5vh;
    width: 100%;
    height: 4.426vh;
    color: #9b9b9b;
    font-size: 14px;
    margin: 1vh 0;
}

.settings-main-btn-save-name, .settings-main-btn-reset, .settings-btn-del-coletion {
    border: solid 1px #1f285a;
    color: #1f285a;
    font-weight: bold;
    border-radius: 8px;
    padding: 0.8vh 0.5vw;
    width: 80px;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    margin: 0 0 0 auto;
}

.settings-btn-peview-album {
    margin: 15px auto 15px 0px;
    display: flex;
    background: #7e187a;
    color: white;
    padding: 0px 1.5em;
    border-radius: 5px;
    height: 35px;
    text-align: center;
    border: 0px;
    cursor: pointer;
}

.settings-btn-peview-album-presentacion, .settings-main-delete {
    margin: auto auto 0px auto;
    background: #1f285a;
    color: white;
    padding: 1.5vh 0;
    width: 100%;
    height: auto;
    text-align: center;
    border: 0px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
}
.settings-btn-peview-album-presentacion > img {
    margin: 0 0 0 auto;
}
.settings-btn-peview-album-presentacion > label {
    cursor: pointer;
    margin: 0 auto 0 10px;
    font-size: 14px;
}

.settings-main-delete {
    margin: auto 0 0 0;
    display: flex;
}
.settings-main-delete > img {
    margin: 0 0 0 auto;
}
.settings-main-delete > label {
    margin: 0 auto 0 0;
    font-size: 14px;
    cursor: pointer;
}

.settings-main-delete-h3 {
    cursor: pointer;
    color: black;
    font-size: 16px;
    display: flex;
}

.settings-main-input > div, .settings-main-delete-h3 {
    text-align: left !important;
    font-weight: bold;
}
