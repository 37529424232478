.main-content-vital-book {
    display: flex;
    height: 92.5vh;
    background: #d8d8d829;
    position: relative;
    overflow: hidden;
}
.main-content-slide-menu {
    width: 200px;
    height: auto;
    background-color: rgb(184, 184, 184);
    border-right: 1px solid grey;
}
.button-loggout-test {
    width: 150px;
    height: 50px;
}
.main-content-header {
    background-image: linear-gradient(93deg, #891558, #7e187a 52%, #1f285a);
    color: white;
    display: flex;
    flex-direction: row;
    flex: 1;
}
.main-content-header-title {
    font-weight: bold;
    text-align: left;
    margin: auto 0px auto 2vw;
    display: flex;
    flex-direction: row;
}
.main-content-header-title > img {
    height: 40px;
}
.main-content-header-title > div {
    margin: auto 0 auto 1vw;
    font-size: 25px;
    cursor: context-menu;
}
.main-content-header-name-vital {
    text-align: right;
    margin: auto 50px auto auto;
    display: flex;
    flex-direction: row;
}
.main-content-header-name-vital > img {
    height: 30px;
    cursor: pointer;
}
.main-content-header-name-vital > div {
    margin: auto 1vw auto 0;
    font-size: 15px;
}

.main-content-buttons-social-networks {
    display: flex;
    justify-content: center;

    padding: 10px 0;
    width: 70%;
    height: 100%;
    max-height: 60px;
    margin: 0 0 0 10px;
    border-radius: 5px;
    border: 1px solid grey;
}
.main-content-buttons-social-networks > img, .test-fb-log {
    height: 100%;
    max-height: 50px;
    max-width: 60px;
    flex: 0.7;
    margin: 0 5px;
    padding: 5px 0;
}
.main-content-buttons-social-networks > img:hover, .test-fb-log:hover {
    background-color: rgb(206, 206, 206);
    border: 1px solid black;
    border-radius: 5px;
}
.main-content-buttons-social-networks > img:active, .test-fb-log:active {
    background-color: rgb(73, 160, 73);
    border: 1px solid black;
    border-radius: 5px;
}
.main-content-view-book {
    display: flex;
    flex: 11 ;
    height: 90vh;
    background: #d8d8d829;
}
/* BAR TYPE IMG SELECTOR */
.main-vertical-tap-bar {
    background: #fff;
    height: 40vh;
    margin: auto 0px;
    flex: 0.4;

    -moz-transition: all 0.75s;
    -webkit-transition: all 0.75s;
    -o-transition: all 0.75s;
    transition: all 0.75s;
}
.main-vertical-tap-bar-show {
    flex: 3;
    height: 100%;
}
.vertical-tap-bar {
    display: flex;
    flex-direction: row;
}
.main-vertical-tap-bar, .vertical-tap-bar {
    -moz-transition: all 0.75s;
    -webkit-transition: all 0.75s;
    -o-transition: all 0.75s;
    transition: all 0.75s;
}
.TabBarImg-arrow-navigation-left, .TabBarImg-arrow-navigation-right {
    cursor: pointer;
    -webkit-transition: all 0.50s linear;
    transition: all 0.50s linear;
}
.TabBarImg-arrow-navigation-left > img {
    max-height: 10px;
    max-width: 10px;
    margin: auto;
    transform: rotate(-90deg);
}
.TabBarImg-arrow-navigation-right > img {
    max-height: 10px;
    max-width: 10px;
    margin: auto;
    transform: rotate(90deg);
}
.btn-upload-local-photos-icon {
    background-color: #1f285a;
    cursor: pointer;
    border-radius: 5px;
    margin: 20px auto 20px 0px;
}
.btn-upload-local-photos-icon > img {
    height: 50%;
    width: 69%;
    margin: auto auto auto 0px;
}
.main-vertical-upload-img-preview {
    height: 50vh !important;
    width: 30vw !important;
}

.main-vertical-tap-img-preview {
    height: 40vh;
    width: 34vw;
    max-width: 593px;
    min-height: 340px;
    flex-direction: column;
    display: flex;
    -webkit-transition: width 0.50s linear, height 0.50s linear;
    transition: width 0.50s linear, height 0.50s linear;
}
.main-vertical-titles {
    margin: 5vh 0 0 0;
    font-family: Roboto;
}
.main-vertical-title {
    font-size: 35px;
    font-weight: bold;
    color: #7e187a;
}
.main-vertical-sub-title {
    font-size: 18px;
    color: #1f285a;
}
.main-vertical-tap-img-preview-facebook {
    margin: auto;
    max-height: 50vh;
    overflow-y: auto; 
}
.main-vertical-tap-controls {
    display: flex;
    flex-direction: row;
    height: 7vh;
    margin: -7vh 0 0 0;
    position: absolute;
}
.main-vertical-tap-controls-import-btn {
    flex: 1;
    background: #7e187a;
    color: white;
    border: none;
    border-radius: 5px;
    height: 35px;
    margin: 0px auto;
    max-width: 175px;
}
.main-vertical-tap-controls-close-btn {
    cursor: pointer;
    flex: 1;
    margin: auto auto auto 10px;
    height: 25px;
    max-width: 25px;
    padding: 10px;
}
.main-vertical-tap-controls-close-btn:hover {
    border-radius: 50%;
    background-color: #8080802c;
}

.vertical-tap-bar > div {
    display: flex;
}
.vertical-tap-bar > div > img {
    height: 100px;
    border-radius:  5px;
}
.custom-bar-selector {
    min-width: none !important;
}
.carrousel-draggable-element {
    height: 100%;
    width: 100%;
}
.carrousel-draggable-element:hover {

}
.carrousel-draggable-element > .carrousel-draggable-element-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.carrousel-draggable-element:hover > .carrousel-draggable-element-icon {
    width: 30px;
    visibility: visible;
}
.carrousel-draggable-element-icon {
    /* height: 30px;
    width: 30px;
    visibility: visible; */

    height: 100%;
    width: 0;
    visibility: hidden;
    margin: 0 0 -100% 0;
    cursor: pointer;
    position: relative;
    -webkit-transition: width 0.2s linear;
    transition: width 0.2s linear;
    background: #92117e;
    z-index: 99999;    
    display: flex;
}
.carrousel-draggable-element-icon > img {
    height: 30px;
    width: 30px;
    margin: auto 0;
}
    /*MENU LATERAL CSS*/
    .component-bar-selector {
        height: 100%;
        box-shadow: 0 2px 4px 0 rgba(99, 99, 99, 0.5);
    }
    .component-bar-selector-menu {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .component-bar-slide-menu {
        height: inherit;
    }
    .component-bar-selector-options {
        flex: 1;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        padding: 1vh 0;
        text-align: -webkit-center;
    }
    .component-bar-selector-options:hover {
        background-color: #7c17781a;
    }
    .component-bar-selector-options > img {
        flex: 1;
        width: 1.5vw;
        margin: auto;
    }
    .component-bar-selector-options > div {
        max-height: fit-content;
        font-size: 14px;
        flex: 3 1;
        margin: auto 0px;
        font-weight: bold;
    }
    .component-bar-selector-option-chose {
        display: flex;
        flex-direction: column;
        height: 100%;
        text-align: left;
        max-width: 25vw;
        font-size: 12px;
        user-select: none;
    }
    .component-bar-selector-option-chose > h4 {
        margin: 0 2vw;
        color: #92117e;
        font-size: 18px;
    }
    .component-bar-selector-option-chose > .close-button {
        margin: 4vh 2vw 0 auto;
        cursor: pointer;
        width: 1.5vh;
        height: 1.5vh;
    }
    .component-bar-selector-option-content {
        height: 100%;
    }
    .main-user-photos {
        display: flex;
        flex-direction: column;
        /* height: 85vh; */
        height: inherit;
        cursor: default;
    }
    .main-user-photos > .user-photos-all-view {
        margin: 0 1vw 0 2vw;
        padding-right: 1vw;
        height: auto;
        overflow-x: hidden;
        user-select: none;
    }
    .user-photos-all-view {
        overflow-y: auto;
        height: auto;
        height: 79.3vh;
    }
    .user-photos-all-view-title {
        margin-top: 1vh;
        color: #1f285a;
        font-size: 16px;
    }
    .user-photos-title-selected-collection {
        font-size: 16px;
        line-height: 1;
        color: #1f285a;
        font-weight: bold;
        margin: 2vh 0 3vh 0;
    }
    .user-photos-selector-buttons {
        margin-top: 3vh;
        margin-bottom: 0.5vh;
        display: flex;
        flex-direction: row;
        color: #1f285a;
        font-size:  16px;
        border-bottom: solid 1px #e2e5e9;
    }
    .user-photos-selector-filter {
        size: 14px;
        cursor: pointer;
        width: 100%;
        text-align: center;
        padding-bottom: 0.5vh;
        border-bottom: 2px solid transparent;
    }
    .user-photos-selector-filter-active {
        font-weight: bold;
        border-bottom: 2px solid #1f285a;
    }
    .user-photos-selector-filter:hover {
        opacity: 0.8;
    }

    .main-user-photos > h4 {
        cursor: pointer;
        color: #1f285a;
        margin-top: 1vh;
        margin-bottom: 1vh;
    }
    .main-user-photos-title {
        color: #1f285a;
        font-size: 14px;
        border-bottom: solid 1px #e2e5e9;
        display: flex;
        flex-direction: row;
        margin-top: 1vh;
        cursor: pointer;
        margin-bottom: 0.5vh;
        padding-bottom: 0.5vh;
    }
    .main-user-photos-title > label {
        cursor: pointer;
    }
    .main-user-photos-title > div {
        cursor: pointer;
    }
    .main-user-photos-title > img {
        cursor: pointer;
        width: fit-content;
        height: fit-content;
        margin: auto 0.37vw auto auto;
        transition: transform 0.5s;
    }

    .user-photos-title-selected {
        font-weight: bold;
        color: #92117e;
    }
    .main-user-photos-title:hover > img {}
    .main-user-photos-title-arrow {
        transform: rotate(180deg);
    }
    .colections-title {
        display: flex;
        flex-direction: row;
        font-size: 16px;
        font-weight: bold;
        margin-top: 2vh;
        margin-bottom: 1vh;
    }
    .colections-title-text {
        color: #1f285a;
        width: fit-content;
    }
    .colections-title-img {
        cursor: pointer;
        width: fit-content;
        margin: 0 0 0 auto;
        width: 2.5vh;
    }

    .main-user-photos-local, .main-user-photos-fb, .main-user-photos-instagram {}
    .user-photos-import {
        cursor: pointer;
        background: #1f285a;
        color: #ffffff;
        width: 100%;
        height: auto;
        margin: auto 0px 0px 0px;
        text-align: center;
        padding: 1.5vh 0;
        font-size: 14px;
    }
    .user-photos-import-img {
        height: 16px;
        width: 20px;
        margin: 0 8px 0 0;
    }
    /*MENU LATERAL CSS*/

/* BAR TYPE IMG SELECTOR */

/* custom input get files*/
.custom-input {
    width: 103px;
    height: 30px;
    margin: 0px;
    padding: 0px;
}
.input-add-music-files {
    opacity: 0;
    background: #1f285a;
    cursor: pointer !important;
    width: 6vw;
}
.custom-input-text-music {
    color: #1f285a;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    margin: 0 0 0 25px;
}

.upp-music-btn-local > label {
    font-size: 14px;
    font-weight: bold;
    color: #1f285a;
}
.upp-music-btn-local > img {
    margin: auto 0 auto 10px;
    height: 12px;
    width: 12px;
    cursor: pointer;
}
.upp-music-btn-local {
    width: fit-content;
    margin: 1vh 0;
}
.upp-music-btn-local > div {
    display: flex;
    flex-direction: row;
    width: fit-content;
    cursor: pointer;
}
.upp-music-btn-local > div > img {
    max-width: fit-content;
    flex: 1;
    min-width: 2vh;
}
.upp-music-btn-local > div > div {
    flex: 1;
    color: #1f285a;
    font-size: 14px;
    font-weight: bold;
    margin-left: 0.5vw;
}
.ViewMenu-music-generate-video {
    display: flex;
    flex-direction: row;
}
.ViewMenu-music-generate-video-btn {
    margin: auto 10px auto 0;
    height: 4vh;
    border: 0px;
    color: #ffffff;
    border-radius: 5px;
    width: fit-content;
    cursor: pointer;
}
.ViewMenu-music-generate-video-active {
    background-color: #7e187a;
}
.ViewMenu-music-generate-video-dissabled {
    cursor: not-allowed;
    background-color: hsla(302, 68%, 29%, 0.3);
}
.custom-input-none {
    display: none;
    background: #1f285a;
    height: 175px;
}
.custom-input-text {
    height: 16px;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    margin: auto 0px;
    position: absolute;
    padding: 10px 6.6em;
}
.profile-container-sidebar-picture-change > label {
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin: auto 0px auto -26px;
    position: absolute;
    padding: 0px;
}
.custom-input + label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: black;
    display: inline-block;
    cursor: pointer;
}
.custom-input:focus + label,
.custom-input + label:hover {
    background-color: red;
}
/* custom input get files*/

/* MainContent album*/
.album-context-remove {
    border-color: #dc143c !important;
}
.album-context {
    flex: 7 1;
    height: auto;
    text-align: center;

    display: flex;
    flex-direction: column;
}
.album-context-pages-paginator {
    background: #fff;
    margin: 0 3.5vw 4vh;
    flex: 10;
    display: flex;
    flex-direction: row;
    
    -webkit-transition : all 0.75s; 
    -moz-transition : all 0.75s;
    -o-transition : all 0.75s;
    transition : all 0.75s;
}
.album-context-album-size-with-menu {
    padding: 2vh 8vw 0;
}
.album-context-album-size {
    padding: 2vh 18.5vw 0;
}
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .album-context-album-size-with-menu {
        padding: 2vh 4vw 0;
    }
    .album-context-album-size {
        padding: 2vh 10vw 0;
    }
}
.album-context-nav-arrow-back, .album-context-nav-arrow-next {
    flex: 1;
    height: 9.5vh;
    background-color: #1f285a;
    max-width: fit-content;
    max-width: 1.6vw;
    margin-top: 30vh;
    cursor: pointer;
    display: flex;
}
.album-context-nav-arrow-back > img {
    transform: rotate(-90deg);
    height: 0.8vh;
    margin: auto;
}
.album-context-nav-arrow-next > img {
    transform: rotate(90deg);
    height: 0.8vh;
    padding: 4.5vh 0;
    margin: auto;
}
.album-context-templates {
    flex: 7;
    min-height: 77vh;
    height: inherit;
    display: flex;
    flex-direction: column;
}
.album-context-templates-preview {
    flex: 7 1;
    min-height: 81vh;
    height: inherit;
    display: flex;
    flex-direction: column;
}
.album-context-pages-paginator-button {}
.album-context-pages {
    display: flex;
    flex-direction: row;
    border: solid 0.42vw #1f285a;
    background-color: #d8d8d829;
}
.album-context-pages > div {
    display: flex;
    flex-direction: row;
    flex: 1;
}
.album-context-pages > div > div > div {
    margin: 1.5vh 0.5vw 3.5vh;
    height: 60vh;
    text-align: left;
    display: flex;
}
.album-context-page {
    border: 2px solid transparent;
    padding: 0.5vh 0.25vw;
    flex: 7 1;
    height: 65vh;
    transition: all 0.75s
}
.album-context-paginator-button {
    display: flex;
    flex-direction: row;
    flex: 1;
}
.album-context-paginator-button > div {
    flex: 1;
    display: flex;
    height: auto;
    max-width: fit-content;
}
.album-context-paginator-numbers {
    margin: 1.75vh 0;
}
.album-context-paginator-numbers > input {
    width: 3vw;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 1px 1px 0 rgba(221, 221, 221, 0.5), inset 0 1px 3px 0 rgba(153, 153, 153, 0.5);
    border: solid 1px #dfe2e6;
    background-color: #ffffff;
    height: 3.1vh;
    margin: auto;
}
.album-context-paginator-numbers > label {
    height: fit-content;
    margin: auto auto auto 0.5vw;
}
.album-context-paginator-button-arrow-back {
    margin: 3vh 1vw 3vh auto;
}
.album-context-paginator-button-arrow-back > img {
    margin: auto;
    text-align: left;
    cursor: pointer;
}
.album-context-paginator-button-arrow-next {
    margin: 3vh auto 3vh 1vw;
}
.album-context-paginator-button-arrow-next > img {
    margin: auto;
    text-align: right;
    cursor: pointer;
}

.page-position-left {
}

.page-position-preview-left {
    margin: 0 auto 0 3.5vw;
}

.page-component {
    width: 50%;
}

.img-delete-pages {
    height: 30px;
    width: 30px;
    background-color: #a5a5a5;
    border-radius: 100%;
    margin: -15px 0 0 -15px;
    cursor: pointer;
}
.img-delete-pages:hover {
    background-color: #b34c4c;
}

.page-position-right {}
.page-position-preview-right {
    margin: 0 3.5vw 0 auto;
}
/* MainContent album*/

.page-component:hover > div > .page-component-button-rm-template-left, .page-component:hover > div > .page-component-button-rm-template-right {
    height: 40px;
    width: 40px;
    visibility: visible;
    padding: 5px;
}

.page-component-button-rm-template-right, .page-component-button-rm-template-left {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    height: 0px;
    width: 0px;
    visibility: hidden;
    -webkit-transition: height 0.25s;
    transition: height 0.25s;
    background-color: transparent;
    padding: 0 15px 15px 0;
}

.page-component-button-rm-template-right {
    margin: -53px 0px 0px 22vw;
}

.page-component-button-rm-template-left {
    margin: -53px 0px 0px -2.2vw;
}

/* Google Drive */
.picker-dialog {
    z-index: 9999 !important;
}
.btn-upload-photos-icon {
    background-color: #1f285a;
    cursor: pointer;
    border-radius: 5px;
    margin: 20px auto 20px 0px;
    display: flex;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 6px;
    width: 100%;
    border: 0;
    font-size: 14px;
}
.btn-upload-photos-icon > img,
.btn-upload-photos-icon > svg {
    margin-right: 8px;
}

/* Facebook fix */
.menu-container-btn-facebook {
    display: flex;
    align-items: center;
}

/* Animated checkmark */
.animated_checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: animated_checkmark_stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}

.animated_checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: animated_checkmark_fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}

.animated_checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: animated_checkmark_stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}

@keyframes animated_checkmark_stroke {
    100% {
        stroke-dashoffset: 0
    }
}

@keyframes animated_checkmark_scale {

    0%,
    100% {
        transform: none
    }

    50% {
        transform: scale3d(1.1, 1.1, 1)
    }
}

@keyframes animated_checkmark_fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #7ac142
    }
}