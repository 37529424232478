
.load-bar-img {
    border: 1px solid grey;
    /* border-radius: 3px; */
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
}
.load-bar-img-error {
    color: rgb(252, 67, 67);
}
.load-bar-img-section {
    flex: 1;
    background: #25ff92;
}