.profile-container {
  height: 10vh;
}
.profile-container-picture-select {
  width: 7.5vh;
  height: 7.5vh;
  margin: auto;
  display: flex;
}
.profile-container-picture-unselect {
  height: 65%;
  width: 70%;
  margin: 15% 15% 20% 15%;
}
.profile-container-picture-first-letter-name {
  height: fit-content;
  width: fit-content;
  margin: 0 auto;
  color: #ffffff;
  font-size: 30px;
  display: flex;
  padding: 2.2vh 0px;
}
.menu-vista-friend-img {

}
.profile-container-picture-img {
  height: 4vh;
  width: 4vh;
  margin: auto 0.1vw auto auto;
  border-radius: 100%;
  background: #92117e;
}
.add-cercle-t {
  border: 1px solid transparent;
}
.add-cercle-w {
  border: 1px solid #ffffff;
}
.profile-container-picture-name-logout {
  display: flex;
  height: 7.5vh;
  margin: auto 2.75vw auto auto;
  position: relative;
  cursor: pointer;
}
.profile-container-name-logout {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.profile-container-name {
  font-size: 30px;
  font-weight: 300;
  letter-spacing: -0.1px;
  color: #000000;
}
.profile-container-picture-down-arrow {
  height: auto;
  width: auto;
  margin: auto 5px;
}
.profile-container-sidebar-btn-legal-info {
  margin: 1vh auto;
  font-size: 14px;
  cursor: pointer;
  color: #1f285a;
  text-decoration: underline #1f285a;
}
.profile-container-sidebar-btn-legal-info > div {
  margin: 2.5vh auto;
  display: flex;
  height: fit-content;
  width: fit-content;
}
.profile-container-logout {
  color: #9b9b9b;
  align-self: flex-end;
  position: relative;
}
.main-profile-container-sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 297px;
}
.main-profile-container-sidebar > div {
  width: 100%;
}
.profile-container-sidebar-user-info {
  overflow-y: auto;
  overflow-x: hidden;
}
.profile-container-sidebar-user-data {
  background: grey;
  color: white;
  min-height: 335px;
}
.profile-container-sidebar-counter-info {
  background-color: #7e187a;
  height: fit-content;
  display: flex;
  flex-direction: row;
  color: white;
  padding: 1.5vh 0px;
}
.profile-container-sidebar-counter-info > div {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.profile-container-sidebar-counter-info > div > label {
  flex: 1;
  font-weight: 300;
  text-align: center;
  color: #ffffff;
}
.profile-container-sidebar-counter-info-name {
  font-size: 12px;
}
.profile-container-sidebar-counter-info-data {
  font-size: 16px;
}
.profile-container-fiscal {
  color: #1f285a;
  font-size: 14px;
  margin: 1vh 2vw;
}
.profile-container-fiscal-money {
  font-weight: bold;
}
.profile-container-sidebar-plan {
  width: 100%;
}
.profile-container-sidebar-plan > h4 {
  margin: 2vh 0 0 0;
}
.profile-container-sidebar-plan > h4,
.profile-container-sidebar-plan-language > h3 {
  color: #7e187a;
  font-size: 16px;
}
.profile-container-sidebar-plan-language-buttons {
  margin: 0 auto 2vh auto;
  width: 50%;
  display: flex;
  justify-content: space-around;
}
div.profile-container-sidebar-plan-separator{
  width: 80%;
  margin: 0 auto;

  border: 0.5px solid #e0e0e0;
}
.profile-container-sidebar-plan-language-buttons > label {
  color: #1f285a;
}
.profile-container-sidebar-plan-language-buttons > label:not(.selected) {
  cursor: pointer;
}
.profile-container-sidebar-plan-language-buttons > label:hover, 
.profile-container-sidebar-plan-language-buttons > label.selected {
  font-weight: bold;
}

.profile-container-sidebar-picture-icon {}
.profile-container-sidebar-picture-change {
  width: 30px;
  height: 30px;
  margin: -23px 7.6vw 20px auto;
  background: #ffffff;
  border-radius: 100%;
  position: relative;
}
.profile-container-sidebar-remove-next-plan {
  border: 1px solid #1f285a;
  color: #1f285a;
  background: #ffffff;
  border-radius: 8px;
  height: 35px;
  font-weight: bold;
  font-size: 14px;
  font-family: Roboto;
  cursor: pointer;
}
.profile-container-sidebar-remove-next-plan:hover {
  color: #ffffff;
  background: #1f285a;
}
.profile-container-sidebar-size {
  color: #1f285a;
  font-size: 14px;
  margin: 2vh 0;
}
[class*='size-progress-bar'] {
  height: 100%;
  border-radius: 15px;
}
.size-progress-bar {
  background-color: #cecece;
  height: 10px;
  width: 75%;
  display: flex;
  position: relative;
  margin: 10px auto 10px auto;
}
[class*='size-progress-bar'] > span {
  right: -15px;
}
.size-progress-bar span {
  font-size: 9px;
  position: absolute;
}
.size-progress-bar > span {
  right: -35px;
  top: calc(50% - 9px/1.5);
  color: #1f285a;
}
.size-progress-bar > div[class*='size-progress-bar'] {
  position: absolute;
}

.profile-container-sidebar-plan-name {
  font-weight: bold;
  color: #1f285a;
  margin: 0.25vh 0 1vh 0;
}
.profile-container-sidebar-plan-description {
  color: #a0a0a0;
  font-size: 11px;

  max-width: 230px;
  margin: 0 auto;

  margin-top: 25px;
  margin-bottom: 15px;
}

button[class*='profile-container-sidebar-btn'] {
  font-weight: bold;
  border: 1px solid;
  font-size: 14px;
  padding: 10px 30px;
  border-radius: 8px;
  background: transparent;
  margin: 15px 0;
  cursor: pointer;
}
button.profile-container-sidebar-btn-edit {
  border-color: white;
  color: white;
}
button.profile-container-sidebar-btn-renew {
  border-color: #1f285a;
  color: #1f285a;
  padding-left: 50px;
  padding-right: 50px;
  font-family: Roboto;
}
button.profile-container-sidebar-btn-renew:hover {
  color: #ffffff;
  background: #1f285a;
}
.profile-container-sidebar-btn-edit:hover {
  background: #ffffff;
  border: 1px solid #ffffff;
  color: #1f285a;
}
.profile-container-sidebar-picture {
  width: 100px;
  height: 100px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-top: 20px;
  align-self: center;
  box-shadow: 0 2px 4px 0 rgba(188, 187, 187, 0.5);
  background: #7e187b;
}
.profile-container-sidebar-nonpicture {
  width: 5vw;
  height: 5vw;
  border-radius: 100%;
  background: transparent;
  border: 3px solid white;
  margin: 0px auto;
}
.profile-container-sidebar-nonpicture > img {
  margin: 1vw;
  height: 3vw;
  width: 3vw;
}
.profile-container-sidebar-close {
  cursor: pointer;
  text-align: right;
  margin: 20px 20px 0px auto;
}
.profile-container-input {
  text-align:center;
  width: 220px;
  height: 40px;
  border-radius: 8px;
  background-color: #1f285a;
  border: 1px solid white;
  font-family: Roboto;
  color: white;
  margin: 30px;
}
.profile-container-custom-input, .profile-container-custom-input-editable {
  border: 1px solid transparent;
  width: auto;
  height: auto;
  margin: 1px 2vw;
  border-radius: 8px;
}
.profile-container-custom-input-editable {
  border: 1px solid white;
}
.profile-container-custom-input-element {
  border: none;
  background: none;
  color: white;
  text-align: center;
  font-size: 14px;
  width: 100%;
}
.profile-container-custom-icon{
  flex: 1;
  align-self: center;
}
.profile-container-datepicker-inputDate {
  border: none;
  text-align:center;
  color: #ffffff;
  background-color: transparent;
  font-family: Roboto;
  outline: none;
  font-size: 12px;
}
.profile-container-button-icons-local {
  height: 95%;
  width: 70%;
  margin: auto auto auto 0px;
}
.profile-container-button-icons {
  margin-right: 10px;
}
.profile-container-button-text{
  margin-top: 3px;
  font-size: 14px;
}
.profile-container-politica-privacidad {
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  margin: 30px;
  cursor: pointer;
}
.profile-container-logout-btn {
  color: white;
  cursor: pointer;
  background: #1f285a;
  width: 100%;
  margin: auto 0px 0px;
  height: 5.5vh;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.profile-container-logout-btn > img {
  margin: auto 0px auto auto;
}
.profile-container-logout-btn > label {
  margin: auto auto auto 10px;
  cursor: pointer;
}
