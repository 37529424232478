.main-invite-friends {
    background: #ffffff;
    flex: 1;
    margin-bottom: 1vh;
    padding: 1vh 1.5vw 0 1.5vw;
    text-align: left;
    display: flex;
    flex-direction: column;
}
.invite-friends-title {
    width: fit-content;
    height: fit-content;
    font-size: 16px;
    font-weight: 600;
    line-height: 0.88;
    color: #1f285a;
    margin-top: 26px;
}
.invite-friends-complement {
    width: fit-content;
    height: fit-content;
    margin: 6px 0px;
    font-size: 14px;
    color: #b3b3b3;
}
.invite-friends-form-list {
    overflow-y: auto;
    max-height: 13vh;
}
.invite-friends-form {
    display: flex;
    flex-direction: row;
}
.invite-friends-form > div {
    flex: 1;
}
.invite-friends-form-close {
    max-width: 2.5vw;
    margin: auto;
    text-align: center;
}
.invite-friends-form-close > img {
    cursor: pointer;
}
.invite-friends-add-list {
    width: 100%;
    border-radius: 8px;
    background: #7c177817;
    display: flex;
    flex-direction: row;
    height: 5vh;
}
.invite-friends-add-list-more-people {
    color: #92117e;
    line-height: 1.17;
    font-weight: 600;
    font-size: 14px;
    margin: auto 0px auto 15px;
    cursor: pointer;
}
.invite-friends-add-list-more-people > label {
    height: 100%;
    margin-left: 5px;
    cursor: pointer !important;
}
.invite-friends-add-list-more-people > img, .invite-friends-add-list-google > img {
    height: 16px;
    width: 16px;
}
.invite-friends-add-list-google {
    color: #b3b3b3;
    font-size: 14px;
    margin: auto 15px auto auto;
}
.invite-friends-add-list-google > label {
    margin-left: 5px;
}
.invite-friends-add-list-google > label > div, .invite-friends-google-and-btn > label > div, .invite-friends-add-list-google-click {
    color: #1f285a;
    cursor: pointer;
}
.invite-friends-google-and-btn {
    margin: auto 0 20px 0;
    display: flex;
    flex-direction: row;
}
.invite-friends-google-and-btn > label {
    margin-left: 5px;
}
.invite-friends-conect-users-fb {
    font-size: 12px;
    color: #b3b3b3;
}
.invite-friends-conect-users-fb-click {
    font-size: 12px;
    color: #1f285a;
    cursor: pointer;
}
.invite-friends-btn {
    color: #ffffff;
    border-radius: 8px;
    background-color: #1f285a;
    border: 0px;
    width: 136px;
    font-size: 14px;
    height: 35px;
    margin: 0px 0px 0px auto;
    cursor: pointer;
}
