.header-main, .header-main-album {
    display: flex;
    flex-direction: row;
    height: 7.5vh;
}
.header-main > div, .header-main-album > div {
    flex: 1;
    display: flex;
}
.header-logo {
    text-align: left;
}
.header-logo > img {
    margin: 0px 0px 0px 2.5vw;
    z-index: 2;
    cursor: pointer;
}
.header-menu-user {
}
.header-menu-user > button, .header-menu-user-album > button {
    background: #1f285a;
    width: fit-content;
    margin: auto 1vw;
    height: 3.7vh;
    padding: 0 1vw;
    border-radius: 8px;
    border: 0px solid;
    color: white;
    cursor: pointer;
    position: relative;
    z-index: 1;
}
.help-btn {
    margin: auto 1vw auto auto;
}
.help-btn > button {
    background: transparent;
    width: fit-content;
    height: 35px;
    padding: 0 1vw;
    border-radius: 8px;
    border: 0px solid;
    color: white;
    cursor: pointer;
    position: relative;
    z-index: 1;
    margin: auto;
}
.header-menu-user > button:hover {
    background: #7e187a;
}
.header-menu-user-album > button:hover {
    background: #ffffff;
    color: #1f285a;
}
/*CHANGE STYLES HEDAER ON ALBUM PAGE*/
.header-main-album {
    background-image: linear-gradient(93deg, #891558, #7e187a 52%, #1f285a) ;
}
.header-menu-user-album > button {
    background: transparent;
    border: 1px solid #ffffff;
}
.header-menu-help-btn-album > button {
    color: #ffffff;
}
.header-menu-help-btn > button {
    color: #1f285a;
}
