.main-menu-vista-friend {
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
}
.menu-vista-friend-btns {
    display: flex;
    flex-direction: row;
    height: 1.6vh;
    padding: 1vh 0 0 0;
}
.menu-vista-friend-btns-vital {
    flex: 1;
    height: 1.6vh;
    display: flex;
}
.menu-vista-friend-btns-vital > img {
    height: 2.4vh;
    margin: 0 auto 0 1vw;
}
.menu-vista-friend-btns-delete { 
    flex: 1;
    height: 1.6vh;
    max-width: fit-content;
    font-size: 12px;
    font-weight: bold;
    text-align: right;
    color: #1f285a;
    margin: auto 1vw auto auto;
    cursor: pointer;
}
.menu-vista-friend-btns-slide {
    flex: 1;
    width: 50%;
    height: auto;
    color: #1f285a;
    cursor: pointer;
    text-align: right;
    margin: 0px auto;
    display: flex;
}
.menu-vista-friend-btns-slide > img {
    height: 1.6vh;
    margin: 0 1vw 0 auto;
}
.menu-vista-friend-img {
    margin: 2vh auto 0 auto;
    height: 9vh;
    width: 9vh;
    border-radius: 100%;
    background: #92117e;
}
.menu-vista-friend-data {
    margin: 0 0 auto 0px;
    display: flex;
    flex-direction: column;
}
.menu-vista-friend-name-user {
    width: 100%;
    height: 2vh;
    font-size: 16px;
    color: #92117e;
    margin: 0.7vh auto;
    font-weight: bold;
}
.menu-vista-friend-date-last-send {
    width: fit-content;
    margin: 0 auto;
    height: fit-content;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    color: #1f285a;
}
 .menu-vista-friend-date-send {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #1f285a;
    cursor: pointer;
    margin: 1vh 0px;
    font-size: 14px;
}
.menu-vista-friend-name-mail {
    color: #1f285a;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}
.menu-vista-friend-date-send > img {
    height: 10px;
    width: 15px;
    margin: 0 0.10vw;
}
.menu-vista-friend-accept-btn {
    cursor: pointer;
    width: fit-content;
    margin: 0px auto;
    padding: 0.3vh 0.25vw;
    height: fit-content;
    font-size: 12px;
    border-radius: 4px;
    border: solid 1px #1f285a;
    background-color: #ffffff;
}
.menu-vista-friend-accept-btn:hover {
    background-color: #1f285a;
    color: white;
}